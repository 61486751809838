import axios from "axios";

export async function ReadAllProductInformations(data = [1, 10, '', '', '', '', '', '']) {
  let [ page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3 ] = data
  let query = `/api/information/product?page=${page}&limit=${limit}`
  if (searchKey1 !== '') {
    query += `&search1[${searchKey1}]=${searchValue1}`
  }
  if (searchKey2 !== '') {
    query += `&search2[${searchKey2}]=${searchValue2}`
  }
  if (searchKey3 !== '') {
    query += `&search3[${searchKey3}]=${searchValue3}`
  }
    try {
      const response = await axios({
        method: 'GET',
        url: process.env.REACT_APP_API_BACKEND + query,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    }
    catch(e) {
      window.location.replace('/500')
    }
}

export async function AddProductInformation(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/product`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function UpdateProductInformation(id, data) {
  try {
    const response = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/product/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function DeleteProductInformation(id) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/product/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}