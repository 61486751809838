import axios from "axios";

export async function ListProducts() {
    try {
      const response = await axios({
        method: 'GET',
        url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/products`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data.data
    }
    catch(e) {
      window.location.replace('/500')
    }
}

export async function ListTribes() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/tribes`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListThemeID() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/themes`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListStatusProducts() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/status-products`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListTypeDigitalizations() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/type-digitalizations`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListTypeProducts() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/type-products`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListCurrentStages() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/current-stages`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListPrioritization() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/prioritization`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListDigitalCategory() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/digital-category`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListBusinessSegment() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/business-segment`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListProductState() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/product-state`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListProductCategory() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/product-category`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListDigitalTheme() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/digital-theme`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListQuarters() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/quarters`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListBudgetType() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/budget-type`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListDateByQuarter(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/date-by-quarter`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListKrByProduct(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/kr-by-product`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListKrId() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/kr`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListKrType() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/kr-type`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListKrUnit() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/kr-unit`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListKrMetric() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/kr-metric`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListMetricType() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/cms/v1/list/kr-metric-type`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch(e) {
    window.location.replace('/500')
  }
}

export async function ListRole() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/users/roles`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data.data
  }
  catch (e) {
    window.location.replace('/500')
  }
}