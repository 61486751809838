import axios from "axios";

export async function ReadAllSalesChannels(data = [1, 10, '', '']) {
  let [page, limit, searchKey, searchValue] = data
  searchKey = searchKey !== null ? searchKey : ''
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/sales-channel?page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  }
  catch (e) {
    window.location.replace('/500')
  }
}

export async function AddChannel(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/sales-channel`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function UpdateChannel(id, data) {
  try {
    const response = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/sales-channel/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function DeleteChannel(id) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/sales-channel/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}