import React, { useState, useEffect } from 'react';
import { Grid, Row, Col, Table, InputGroup, Input, Icon, InputPicker, Button, Modal, Alert, Dropdown } from 'rsuite';
import { jsPDF } from "jspdf";
import Sidebar from '../components/Sidebar';
import { encryptStorage } from '../config';
import { ListCurrentStages, ListStatusProducts, ListThemeID, ListTribes, ListTypeDigitalizations, ListTypeProducts } from '../services/List';
import { AddProduct, DeleteProduct, ReadAllProducts, UpdateProduct } from '../services/Products';

const { Column, HeaderCell, Cell, Pagination } = Table

function Products() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey1, setSearchKey1] = useState('')
  const [searchKey2, setSearchKey2] = useState('')
  const [searchKey3, setSearchKey3] = useState('')
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'product_name',
      label: 'Product Name'
    },
    {
      value: 'status_product',
      label: 'Product Status'
    },
    {
      value: 'type_product',
      label: 'Product Type'
    },
    {
      value: 'type_digitalization',
      label: 'Digitalization'
    },
    {
      value: 'current_stage',
      label: 'Stage'
    },
    {
      value: 'themeID',
      label: 'Theme ID'
    },
    {
      value: 'tribe_id',
      label: 'Tribe ID'
    }
  ]

  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [title, setTitle] = useState('Add ')
  const [product, setProduct] = useState('')
  const [productID, setProductID] = useState('')
  const [status, setStatus] = useState('')
  const [theme, setTheme] = useState('')
  const [tribe, setTribe] = useState('')
  const [digitalization, setDigitalization] = useState('')
  const [type, setType] = useState('')
  const [stage, setStage] = useState('')
  const [fixed, setFixed] = useState(false)
  const [listTribe, setListTribe] = useState('')
  const [listThemeID, setListThemeID] = useState('')
  const [listStatusProduct, setListStatusProduct] = useState('')
  const [listTypeDigitalizations, setListTypeDigitalizations] = useState('')
  const [listTypeProducts, setListTypeProducts] = useState('')
  const [listCurrentStages, setListCurrentStages] = useState('')
  const role = encryptStorage.getItem('role') === 'creator'

  async function getAllProducts() {
    const response = await ReadAllProducts() || { data: [], pageInfo: {} }
    setListUser(response)
  }
  async function getListTribes() {
    const resultTribes = []
    const listTribe = await ListTribes() || []
    for (const list of listTribe) {
      resultTribes.push({
        value: list.tribeID,
        label: list.tribeID
      })
    }
    setListTribe(resultTribes)
  }
  async function getListThemeID() {
    const results = []
    const lists = await ListThemeID() || []
    for (const list of lists) {
      results.push({
        value: list.themeID,
        label: list.themeID
      })
    }
    setListThemeID(results)
  }
  async function getListStatusProducts() {
    const results = []
    const lists = await ListStatusProducts() || []
    for (const list of lists) {
      results.push({
        value: list.status_product,
        label: list.status_product
      })
    }
    setListStatusProduct(results)
  }
  async function getListTypeDigitalizations() {
    const results = []
    const lists = await ListTypeDigitalizations() || []
    for (const list of lists) {
      results.push({
        value: list.type_digitalization,
        label: list.type_digitalization
      })
    }
    setListTypeDigitalizations(results)
  }
  async function getListTypeProducts() {
    const results = []
    const lists = await ListTypeProducts() || []
    for (const list of lists) {
      results.push({
        value: list.type_product,
        label: list.type_product
      })
    }
    setListTypeProducts(results)
  }
  async function getListCurrentStages() {
    const results = []
    const lists = await ListCurrentStages() || []
    for (const list of lists) {
      results.push({
        value: list.current_stage,
        label: list.current_stage
      })
    }
    setListCurrentStages(results)
  }

  useEffect(() => {
    getAllProducts()
    getListTribes()
    getListThemeID()
    getListStatusProducts()
    getListTypeDigitalizations()
    getListTypeProducts()
    getListCurrentStages()
  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllProducts(data)
    setListUser(response)
  }
  async function changeLimit(e) {
    setLimit(e)
    const data = [pageInfo.page, e, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllProducts(data)
    setListUser(response)
  }
  async function searchData(e, searchKey) {
    let data = []
    if (searchKey === 'search1') {
      setSearchValue1(e)
      data = [pageInfo.page, limit, searchKey1, e, searchKey2, searchValue2, searchKey3, searchValue3]
    }
    if (searchKey === 'search2') {
      setSearchValue2(e)
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, e, searchKey3, searchValue3]
    }
    if (searchKey === 'search3') {
      setSearchValue3(e)
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, e]
    }
    const response = await ReadAllProducts(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "productID": productID,
      "productName": product,
      "typeProduct": type,
      "typeDigitalization": digitalization,
      "statusProduct": status,
      "tribeID": tribe,
      "themeID": theme,
      "currentStage": stage
    }
    const response = await AddProduct(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllProducts()
  }
  async function updateData(e) {
    const id = productID
    const data =
    {
      "productName": product,
      "typeProduct": type,
      "typeDigitalization": digitalization,
      "statusProduct": status,
      "tribeID": tribe,
      "themeID": theme,
      "currentStage": stage
    }
    const response = await UpdateProduct(id, data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    getAllProducts(dataList)
    setShow(!show)
  }
  async function deleteData(e) {
    const id = productID
    const response = await DeleteProduct(id)
    response ? Alert.success('Delete Product Success') : Alert.error('Failed to delete Product')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    getAllProducts(data)
  }

  function addNewData() {
    setTitle('Add ')
    setProductID('')
    setProduct('')
    setStatus('')
    setTheme('')
    setTribe('')
    setDigitalization('')
    setType('')
    setStage('')
    setFixed(false)
    setUpdate(false)
    setShow(true)
  }

  function exportToCsv(data) {
    let CsvString = "";
    data.forEach(function (RowItem, RowIndex) {
      RowItem.forEach(function (ColItem, ColIndex) {
        let makeSpace = ColItem.replace(/,/g, "");

        CsvString += makeSpace + ',';
      });
      CsvString += "\r\n";
    });
    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    let downloadElement = document.createElement("A");
    downloadElement.setAttribute("href", CsvString);
    downloadElement.setAttribute("download", "Products.csv");
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }

  async function getDataInEveryPage(page) {
    const data = [page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllProducts(data)
    return response.data;
  }

  function handleDownloadToCSV() {
    let totalLoop = pageInfo.totalPage;
    let arrToCsvData = [["Product ID", "Product Name", "Product Status", "Product Type", "Digitalization Type", "Stage", "Theme ID", "Tribe ID"]];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = [res.productID, res.product_name, res.status_product, res.type_product, res.type_digitalization, res.current_stage, res.themeID, res.tribe_id]
            arrToCsvData.push(dataToPush)
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            exportToCsv(arrToCsvData);
          }
        })

    }
  };

  function handleDownloadPdf() {
    let totalLoop = pageInfo.totalPage;
    let result = [];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = {
              Product_ID: res.productID,
              Product_Name: res.product_name,
              Product_Status: res.status_product ? res.status_product : " ",
              Product_Type: res.type_product ? res.type_product : " ",
              Digitalization_Type: res.type_digitalization,
              Stage: res.current_stage ? res.current_stage : " ",
              Theme_ID: res.themeID,
              Tribe_ID: res.tribe_id
            }
            result.push(Object.assign({}, dataToPush));
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            let headers = createHeaders([
              "Product_ID", "Product_Name", "Product_Status", "Product_Type", "Digitalization_Type", "Stage", "Theme_ID", "Tribe_ID"
            ]);
            generatePdf(result, headers);
          }
        })

    }
  };

  function createHeaders(keys) {
    let result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        name: keys[i],
        prompt: updateStringForHeader(keys[i]),
        width: 45,
        align: "center",
        padding: 0,
        fontSize: 9
      });
    }
    return result;
  }

  function updateStringForHeader(string) {
    let makeSpace = string.replace("_", " ");
    return makeSpace.toLowerCase()
  }

  function generatePdf(data, headers) {
    const pdf = new jsPDF({
      orientation: 'l',
      format: 'a4',
      putOnlyUsedFonts: true
    });
    pdf.table(5, 5, data, headers, { autoSize: true, fontSize: 10.5, marginTop: 10 });
    pdf.save('Product.pdf');
  }

  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }
  async function handleSearchKey(e, searchKey) {
    if (searchKey === 'search1') {
      setSearchKey1(e)
      setSearchValue1('')
    }
    if (searchKey === 'search2') {
      setSearchKey2(e)
      setSearchValue2('')
    }
    if (searchKey === 'search3') {
      setSearchKey3(e)
      setSearchValue3('')
    }
    if (e === null) {
      const data = [pageInfo.page, limit, '', '', '', '', '', '']
      const response = await ReadAllProducts(data)
      setListUser(response)
    }
  }
  const [dataSearch, setDataSearch] = useState([])
  const [iterate, setIterate] = useState(1)
  function handleSearchData() {
    if (iterate > 3) return
    setIterate(iterate + 1)
    setDataSearch([...dataSearch, 'search' + iterate])
  }
  async function deleteSearchMenu(val) {
    setIterate(iterate - 1)
    const newDataSearch = [...dataSearch]
    newDataSearch.pop()
    setDataSearch(newDataSearch)

    let data = []
    if (val === 'search1') {
      data = [pageInfo.page, limit, '', '', '', '', '', '']
    }
    if (val === 'search2') {
      data = [pageInfo.page, limit, searchKey1, searchValue1, '', '', '', '']
    }
    if (val === 'search3') {
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, '', '']
    }
    const response = await ReadAllProducts(data)
    setListUser(response)
  }
  const searchMenu = (data) => {
    let searchValue = ''
    if (data === 'search1') {
      searchValue = searchValue1
    }
    if (data === 'search2') {
      searchValue = searchValue2
    }
    if (data === 'search3') {
      searchValue = searchValue3
    }
    return <Row style={{ justifyContent: 'flex-start', display: 'flex' }}>
      <InputPicker placeholder="Search By" data={searchOptions} style={{ width: 200, margin: 10 }} onChange={e => handleSearchKey(e, data)} />
      <InputGroup style={styles}>
        <Input value={searchValue} onChange={(e) => searchData(e, data)} />
      </InputGroup>
      <Button onClick={e => deleteSearchMenu(data)} style={{ marginLeft: 30, backgroundColor: 'transparent' }}>
        <Icon icon="trash-o" />
      </Button>
    </Row>
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'3-1'} openKey={'3'} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Products</h2>
          </Row>
          <Row>
            <Button onClick={handleSearchData} style={{ marginTop: 5, float: 'left' }}>Add Filters</Button>
            <Dropdown title="Export" style={{ float: 'right', fontSize: '0.8rem', height: '50%', marginLeft: 10 }}>
              <Dropdown.Item onClick={handleDownloadPdf}>Export PDF</Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadToCSV}>Export CSV</Dropdown.Item>
            </Dropdown>
            {role ? <Button style={{ float: 'right', marginLeft: '55%', fontSize: '0.8rem', height: '50%' }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button> : ''}
          </Row>
          {dataSearch.map((data) => searchMenu(data))}
          <Row style={{ marginTop: 25 }}>
            <Table
              virtualized
              height={500}
              width={1050}
              data={listUser.data}
              onRowClick={data => {
                setTitle('Update ')
                setProductID(data.productID)
                setProduct(data.product_name)
                setStatus(data.status_product)
                setTheme(data.themeID)
                setTribe(data.tribe_id)
                setDigitalization(data.type_digitalization)
                setType(data.type_product)
                setStage(data.current_stage)
                setFixed(true)
                setUpdate(true)
                setShow(role ? true : false)
              }}
              loading={listUser.data ? false : true}
            >
              <Column width={100} align="center">
                <HeaderCell>Product ID</HeaderCell>
                <Cell dataKey="productID" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Product Name</HeaderCell>
                <Cell dataKey="product_name" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Product Status</HeaderCell>
                <Cell dataKey="status_product" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Product Type</HeaderCell>
                <Cell dataKey="type_product" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Digitalization Type</HeaderCell>
                <Cell dataKey="type_digitalization" />
              </Column>
              <Column width={200} align="center">
                <HeaderCell>Stage</HeaderCell>
                <Cell dataKey="current_stage" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Theme ID</HeaderCell>
                <Cell dataKey="themeID" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Tribe ID</HeaderCell>
                <Cell dataKey="tribe_id" />
              </Column>

            </Table>

            <Pagination
              width={1050}
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={changeLimit}
            />
          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='xs' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('Product')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}> Product ID</InputGroup.Addon>
            <Input disabled={fixed} value={productID} onChange={(e) => setProductID(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}> Product Name</InputGroup.Addon>
            <Input value={product} onChange={(e) => setProduct(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Status Product</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={status} data={listStatusProduct} onChange={(e) => setStatus(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Digitalization Type</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={digitalization} data={listTypeDigitalizations} onChange={(e) => setDigitalization(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Product Type</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={type} data={listTypeProducts} onChange={(e) => setType(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Stage</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={stage} data={listCurrentStages} onChange={(e) => setStage(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Theme ID</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={theme} data={listThemeID} onChange={(e) => setTheme(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Tribe ID</InputGroup.Addon>
            <InputPicker placeholder=" " value={tribe} data={listTribe} onChange={(e) => setTribe(e)} style={{ border: "none" }} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {role ? choiceUpdate() : ''}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body style={{ color: 'red' }}>
          <h6>Be Carefull of this Action.</h6>
          <h6>Product is Primary Data.</h6>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

const input = {
  width: '8rem'
}

export default Products