import axios from "axios";

export async function getDigitalProductOverview() {
    try {
        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/portofolio/products`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (response.data.code === 403) {
            window.location.replace('/login')
        }
        return response.data
    } catch (e) {
        window.location.replace('/500');
    }
}

export async function getDigitalTheme() {
    try {
        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/portofolio/digital-theme`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (response.data.code === 403) {
            window.location.replace('/login')
        }
        return response.data
    } catch (e) {
        window.location.replace('/500');
    }
}
