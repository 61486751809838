import React from 'react'
import 'rsuite/dist/styles/rsuite-default.css'
import { Sidenav, Nav, Dropdown, Icon } from 'rsuite'
import { useHistory } from 'react-router-dom';
import { encryptStorage } from '../config';

function Sidebar(props) {
  let history = useHistory()
  const { keyActive, openKey } = props
  
  const headerStyles = {
    padding: 20,
    fontSize: 30,
    background: '#eb5757',
    color: ' #fff',
    fontWeight: 'bold'
  }
  
  function logout() {
    history.push('/login')
    localStorage.clear()
  }

  function redirect(key) {
    history.push(key)
  }
  
  return (
    <div style={{ width: "100%" }}>
    <Sidenav defaultOpenKeys={[openKey]} activeKey={keyActive} appearance="default" >
    <Sidenav.Header>
        <div style={headerStyles}>CX Telkom</div>
      </Sidenav.Header>
      <Sidenav.Body>
        <Nav>
          <Nav.Item eventKey="1" icon={<Icon icon="dashboard" />} onClick={() => redirect('/')}>
            Dashboard
          </Nav.Item>
          {encryptStorage.getItem('role') === 'creator' ? <Nav.Item eventKey="2" icon={<Icon icon="group" />} onClick={() => redirect('/users')}>
            User Management
          </Nav.Item> : '' }
          {encryptStorage.getItem('role') === 'creator' ? 
          <Dropdown eventKey="7" icon={<Icon icon="th" />} title="Digital Management">
            <Dropdown.Item eventKey="7-1" onClick={() => redirect('/tribes')}>Tribes</Dropdown.Item>
            <Dropdown.Item eventKey="7-2" onClick={() => redirect('/digital-themes')}>Digital Theme</Dropdown.Item>
            <Dropdown.Item eventKey="7-3" onClick={() => redirect('/quarters')}>Quarter</Dropdown.Item>
          </Dropdown> 
          : '' }
          <Dropdown eventKey="3" title="Products" icon={<Icon icon="pc" />}>
            <Dropdown.Item eventKey="3-1" onClick={() => redirect('/products')}>Products</Dropdown.Item>
            <Dropdown.Item eventKey="3-2" onClick={() => redirect('/product-informations')}>Informations</Dropdown.Item>
            <Dropdown.Item eventKey="3-4" onClick={() => redirect('/competitors')}>Competitors</Dropdown.Item>
            <Dropdown.Item eventKey="3-5" onClick={() => redirect('/ncx-readiness')}>NCX Readiness</Dropdown.Item>
            <Dropdown.Item eventKey="3-6" onClick={() => redirect('/sales-channels')}>Sales Channels</Dropdown.Item>
          </Dropdown>
          <Dropdown eventKey="4" title="Performance" icon={<Icon icon="logo-analytics" />}>
            <Dropdown.Item eventKey="4-1" onClick={() => redirect('/key-results')}>Key Results</Dropdown.Item>
            <Dropdown.Item eventKey="4-2" onClick={() => redirect('/key-result-recaps')}>Recapitulations</Dropdown.Item>
          </Dropdown>
          <Dropdown eventKey="5" title="Budget" icon={<Icon icon="credit-card" />}>
            <Dropdown.Item eventKey="5-1" onClick={() => redirect('/budget-realizations')}>Realizations</Dropdown.Item>
          </Dropdown>
          <Dropdown
            eventKey="6"
            title="Settings"
            icon={<Icon icon="gear-circle" />}
          >
            <Dropdown.Item eventKey="6-1" onClick={logout}>
              Logout
            </Dropdown.Item>
          </Dropdown>
        </Nav>
      </Sidenav.Body>
    </Sidenav>
  </div>
  )
}

export default Sidebar