import axios from "axios";
import { history } from '../config'

export async function ReadAllCompetitors(data = [1, 10, '', '']) {
  let [ page, limit, searchKey, searchValue ] = data
  searchKey = searchKey !== null ? searchKey : ''
    try {
      const response = await axios({
        method: 'GET',
        url: process.env.REACT_APP_API_BACKEND + `/api/information/competitor?page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    }
    catch(e) {
      return window.location.replace('/500')
    }
}

export async function AddCompetitor(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/competitor`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function UpdateCompetitor(idCompetitor, data) {
  try {
    const response = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/competitor/${idCompetitor}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function DeleteCompetitor(competitorID) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/competitor/${competitorID}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}