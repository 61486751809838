import React from "react";
import {
    Grid,
    Paper,
    Typography,
    makeStyles
} from "@material-ui/core";
import {
    ComposedChart,
    Bar,
    Line,
    XAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
    YAxis,
} from "recharts";

import DownIc from '../images/ic-redTriangle.png';
import UpIc from '../images/ic-greenTriangle.png';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="tooltip-label">{label}</p>
                <p className="tooltip-value">{`value : ${numberLimiter(payload[0].value)}`}</p>
                {payload[1] ? <p className="tooltip-cummulative">{`cummulative : ${numberLimiter(payload[1].value)}`}</p> : null}
            </div>
        );
    }

    return null;
};

const numberLimiter = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const abbreviateNumber = value => {
    var newValue = value;
    if (value >= 1000) {
        let suffixes = ["", "K", "M", "B", "T"];
        const searchSuffixNum = Math.floor(Math.log(value) / Math.log(1000));
        let suffixNum = searchSuffixNum > 4 ? 4 : searchSuffixNum;
        let result = parseFloat((value / Math.pow(1000, suffixNum)).toFixed(2));

        return result += `${suffixes[suffixNum]}`;
    }
    return newValue;
}

const useStyles = makeStyles((theme) => ({
    textChartDesc: {
        fontSize: 25,
        fontWeight: 700,
    },
    textChartDesc2: {
        fontSize: 15,
        fontWeight: 700,
    },
    textChartDesc3: {
        fontSize: 10,
        fontWeight: 700,
        color: '#929292',
        margin: 'auto 1px'
    },
    textChartTitle: {
        fonSize: 12,
        fontWeight: 700,
        color: '#9FA2B4'
    },

}));

export default function MultiChart({ chartData, isIncrease, previousYear, name, thisYearValue, percentGainLoss }) {
    const classes = useStyles();
    const formatter = (value) => abbreviateNumber(value);

    return (
        <Grid item xs={12} md={12} style={{ padding: 10 }}>
            <Paper style={{ height: 380, minWidth: 350 }}>
                {name === "GMV" || name === "REVENUE" ? <Typography align="center" className={classes.textChartTitle}>{name} (YTD)</Typography> : <Typography align="center" className={classes.textChartTitle}>{name}</Typography>}
                <Typography align="center" className={classes.textChartDesc}>{thisYearValue ? thisYearValue : ''}</Typography>
                {/* <Grid item container justifyContent="center" alignContent="center">
                    <Typography align="center" className={classes.textChartDesc2}>
                        {isIncrease ? (
                            <img src={UpIc} alt="cunsomer digital icon" width="10" height="10" />
                        ) : (
                            <img src={DownIc} alt="cunsomer digital icon" width="10" height="10" />
                        )}
                        {percentGainLoss ? percentGainLoss : '-'}
                    </Typography>
                    <Typography align="justify" className={classes.textChartDesc3}>(vs {previousYear})</Typography>
                </Grid> */}
                <br />
                <ResponsiveContainer width={"100%"} height="75%">
                    <ComposedChart data={chartData} margin={{ top: 0, right: 45, bottom: 55, left: 45 }}>
                        <XAxis dataKey="date" angle={90} tickMargin={35} />
                        <YAxis yAxisId={1} type="number" domain={[0, parseInt(chartData[chartData.length - 1].actual_value)]} tickFormatter={formatter} />
                        {name === "GMV" || name === "REVENUE" ? <YAxis yAxisId={2} orientation="right" type="number" domain={[0, parseInt(chartData[chartData.length - 1].comulative_value)]} tickFormatter={formatter} /> : ''}
                        <Tooltip content={<CustomTooltip />} />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Bar yAxisId={1} dataKey="actual_value" barSize={20} fill="#EA6E6F" />
                        {name === "GMV" || name === "REVENUE" ? <Line yAxisId={2} type="monotone" dataKey="comulative_value" stroke="#ff7300" /> : ''}
                    </ComposedChart>
                </ResponsiveContainer>
            </Paper>
        </Grid>
    );
};