import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Typography,
    Grid,
    Paper,
    CircularProgress,
    TextField
} from '@material-ui/core';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "rsuite";

import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';
import OkrTable from '../components/okrTable';
import MultiChart from '../components/MultiChart';
import DownIc from '../images/ic-redTriangle.png';
import UpIc from '../images/ic-greenTriangle.png';
import '../App.css';
import 'react-circular-progressbar/dist/styles.css';

import {
    getCostAbsorbtionQuarter,
    getMetricKr,
    getProductOverviewData,
    getTotalCostByQuarterData,
    getTribeList,
    getTribeProductList,
} from '../services/ProductOverview';

import {
    getOkrQuarterAndDate,
    filterOkrDataTable
} from '../services/OkrOverview';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
    },
    paper: {
        paddingLeft: 20,
        color: theme.palette.text.secondary,
    },
    user: {
        borderLeft: '1px solid #DFE0EB'
    },
    pageTitle: {
        fontSize: 24,
        fontWeight: 700,
    },
    paperProductContainer: {
        [theme.breakpoints.up('xs')]: {
            height: 128,
        },
        [theme.breakpoints.down('xs')]: {
            height: 228,
        },
    },
    paperAboutContainer: {
        [theme.breakpoints.up('xm')]: {
            height: 335,
        },
        [theme.breakpoints.down('xm')]: {
            height: 600,
        },
        padding: 32,
    },
    papperContainer: {
        paddingRight: 15,
        paddingBottom: 15,
    },
    chartPaper: {
        height: 263,
        padding: 25,
    },
    selectContainer: {
        padding: '10px 32px 32px 32px',
    },
    textAbout: {
        fontSize: 19,
        fontWeight: 700,
    },
    textAboutTitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#5C5D66',
        marginBottom: 16,
    },
    textAboutDesc: {
        fontSize: 14,
        fontWeight: 600,
        paddingRight: 10,
    },
    aboutValueContainer: {
        margin: '30px 0'
    },
    textChartTitle: {
        fonSize: 12,
        fontWeight: 700,
        color: '#9FA2B4'
    },
    textChartValue: {
        fontSize: 40,
        fontWeight: 700,
    },
    textChartDesc: {
        fontSize: 25,
        fontWeight: 700,
    },
    textChartDesc2: {
        fontSize: 15,
        fontWeight: 700,
    },
    textChartDesc3: {
        fontSize: 10,
        fontWeight: 700,
        color: '#929292',
        margin: 'auto 1px'
    },

}));

function isEmpty(obj) {
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;

}

function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        let suffixes = ["", "K", "M", "B", "T"];
        const searchSuffixNum = Math.floor(Math.log(value) / Math.log(1000));
        let suffixNum = searchSuffixNum > 4 ? 4 : searchSuffixNum;
        let result = parseFloat((value / Math.pow(1000, suffixNum)).toFixed(2));

        return result += `${suffixes[suffixNum]}`;
    }
    return newValue;
}

function numberLimiter(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const getPreviousYear = () => {
    const currentYear = new Date().getFullYear();

    let previousYear = currentYear - 1;

    return previousYear;
}

const persentGainLoss = (lastYear, thisYear) => {
    let count = (thisYear - lastYear) / lastYear * 100;
    if (lastYear === 0 || lastYear === null) {
        return 100;
    } else {
        return count;
    }
}

const ProductOverview = () => {
    const classes = useStyles();
    const [absorpThisQuarter, setAbsorpThisQuarter] = useState(0);
    const [differenceCostAbsorbtionQuarter, setDifferenceCostAbsorbtionQuarter] = useState('');
    const [ruleOfThumb, setRuleOfThumb] = useState('');
    const [tribe, setTribe] = useState('');
    const [date, setDate] = useState(null);
    const [okrPeriod, setOkrPeriod] = useState(null);
    const [product, setProduct] = useState(null);
    const [productName, setProductName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [increase, setIncrease] = useState(true);
    const [isOptionDisable, setIsOptionDisable] = useState(true);
    const [isOptionQuarterDisable, setIsOptionQuarterDisable] = useState(true);
    const [dateList, setDateList] = useState([]);
    const [okrDataTable, setOkrDataTable] = useState([]);
    const [okrPeriodList, setOkrPeriodList] = useState([]);
    const [productDataList, setProductDataList] = useState([]);
    const [productOverviewData, setProductOverviewData] = useState([]);
    const [totalCostByQuarter, setTotalCostByQuarter] = useState([]);
    const [tribeDataList, setTribeDataList] = useState([]);
    const [revenue, setRevenue] = useState({});
    const [GMV, setGMV] = useState({});
    const [MAU, setMAU] = useState({});
    const [WAU, setWAU] = useState({});
    const [DAU, setDAU] = useState({});    

    const history = useHistory();
    const { tokenParam } = useParams();
    if (tokenParam && tokenParam) {
        localStorage.setItem('token', tokenParam);
    }

    const handleChangeDate = (event) => {
        if (event) {
            setDate(event.date);
            filterDataTable(event.date, product);
        } else {
            setDate(null);
        }

    };

    const filterDataTable = async (selectedDate, selectedPeriod) => {
        let getTableData = await filterOkrDataTable(selectedDate, selectedPeriod);
        setOkrDataTable(getTableData.data);
        setRuleOfThumb(convertDataToPercent(getTableData.ruleOfThumb));
    }

    const handleChangeOkrPeriod = (event) => {
        if (event) {
            setOkrPeriod(event.quarter);
            getDateForSelect(okrPeriodList, event.quarter);
            costAbsorbtionQuarterData(event.quarter, product);
            setIsOptionDisable(false);
        } else {
            setOkrPeriod(null);
            setIsOptionDisable(true);
        }
        setDate(null);
    };

    const handleChangeTribe = (event) => {
        setIsOptionQuarterDisable(true);
        setIsOptionDisable(true);
        if (event) {
            setTribe(event.tribeID);
            tribeProductList(event.tribeID);
        } else {
            setTribe(null);
            setIsOptionQuarterDisable(true);
            setIsOptionDisable(true);
        }
        setOkrPeriod(null);
        setDate(null);
        setProductName(null);
        setOkrDataTable([]);
        setRuleOfThumb(convertDataToPercent(''));
    };

    const handleChangeProduct = (event) => {
        setIsOptionQuarterDisable(true);
        setIsOptionDisable(true);
        if (event) {
            setProduct(event.productID);
            setProductName(event.product_name);
            productAllData(event.productID);
            totalCostByQuarterData(event.productID);
            setIsOptionQuarterDisable(false)
            metricKrData(event.productID, 'REVENUE');
            metricKrData(event.productID, 'GMV');
            metricKrData(event.productID, 'MAU');
            metricKrData(event.productID, 'WAU');
            metricKrData(event.productID, 'DAU');
            setOkrDataTable([]);
            setRuleOfThumb(convertDataToPercent(''));
        } else {
            setProduct(null);
            setIsOptionQuarterDisable(true);
            setIsOptionDisable(true);
        }
        setOkrPeriod(null);
        setDate(null);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                await setLoading(true);
                await tribeList();
                await quarter();
                await setLoading(false);
            } catch (err) {
                Alert.error("Failed to get Data")
                setLoading(false)
                history.push("/500")
            }
        };
        getData();
    }, [history])

    const metricKrData = async (productId, krID) => {
        let krData = await getMetricKr(productId, krID);
        if (krID === 'REVENUE') {
            setRevenue(krData.data[0])
        } else if (krID === 'GMV') {
            setGMV(krData.data[0])
        } else if (krID === 'MAU') {
            setMAU(krData.data[0])
        } else if (krID === 'WAU') {
            setWAU(krData.data[0])
        } else if (krID === 'DAU') {
            setDAU(krData.data[0])
        }
    }

    const quarter = async () => {
        let quarterSelect = await getOkrQuarterAndDate();
        setOkrPeriodList(quarterSelect.data);
    }

    const costAbsorbtionQuarterData = async (quarter, productId) => {
        let absorbtionData = await getCostAbsorbtionQuarter(quarter, productId);
        let thisYear;
        let lastYear;
        if (absorbtionData.data) {
            thisYear = absorbtionData.data.absorpThisQuarter * 100;
            lastYear = absorbtionData.data.absorpLastQuarter * 100;
            differenceAmongQuarter(lastYear, thisYear);
            setAbsorpThisQuarter(thisYear);
        } else {
            thisYear = 0 * 100;
            lastYear = 0 * 100;
            differenceAmongQuarter(lastYear, thisYear);
            setAbsorpThisQuarter(thisYear);
            setOkrDataTable([]);
        }

    }

    const tribeList = async () => {
        let tribe = await getTribeList();
        setTribeDataList(tribe.data);
    }

    const totalCostByQuarterData = async (id) => {
        let costByQuarter = await getTotalCostByQuarterData(id);
        setTotalCostByQuarter(costByQuarter.data);
    }

    const tribeProductList = async (data) => {
        let tribeProduct = await getTribeProductList(data);
        setProductDataList(tribeProduct.data);
    }

    const productAllData = async (data) => {
        let productOverview = await getProductOverviewData(data);
        if (productOverview.data === undefined) {
            setProductOverviewData([]);
        } else {
            setProductOverviewData(productOverview.data);
        }
    }

    const newDataOnMultiChartValue = (data) => {
        let newArr = [];
        let valueBefore = 0;
        data.map((res, index) => {
            let actualValue = res.actual_value ? parseInt(res.actual_value) : 0;
            let sumValue = actualValue + valueBefore;
            let obj = {
                date: res.date,
                actual_value: actualValue,
                comulative_value: sumValue.toString(),
                value: numberLimiter(actualValue)
            };
            valueBefore = sumValue;
            return newArr.push(obj);
        })
        return newArr;
    };

    const arrOfObjIsEmpty = (data) => {
        if (data.length === 0) {
            let obj = {
                budget: "0",
                quarter: ""
            };
            return obj;
        } else {
            let newArr = [];
            data.map(res => {
                let obj = {
                    budget: res.budget,
                    quarter: res.quarter,
                    comulative: res.comulative
                };
                return !res.total ? newArr.push(obj) : '';
            });
            return newArr;
        };
    };

    const getDateForSelect = (arr, period) => {
        let obj = arr.find(date => date.quarter === period);
        setDateList(obj.listDate);
    };

    const arrayOfObjectToString = (data, label) => {
        let length = data.length;
        let str = '';;
        if (length === 0) {
            return '-';
        } else {
            if (label === 'competitor') {
                data.forEach((res, index) => {
                    str += res.competitor;
                    if (length === index + 1) {
                        str += ``;
                    } else {
                        str += `, `;
                    }
                })
                return str
            } else if (label === 'readine') {
                data.forEach((res, index) => {
                    str += res.ncx_readine;
                    if (length === index + 1) {
                        str += ``;
                    } else {
                        str += `, `;
                    }
                })
                return str
            } else if (label === 'sales') {
                data.forEach((res, index) => {
                    str += res.sales_channel;
                    if (length === index + 1) {
                        str += ``;
                    } else {
                        str += `, `;
                    }
                })
                return str;
            } else {
                return '-';
            }
        };
    };

    const convertDataToPercent = (data) => {
        if (data === null || data === undefined) {
            return 0;
        } else {
            let percent = data * 100;
            if (percent > 100) {
                return 100;
            } else {
                return percent.toFixed(2);
            }
        }
    };

    const differenceAmongQuarter = (quarter, newQuarter) => {
        let calculation = ((newQuarter - quarter) / newQuarter) * 100;
        if (quarter > newQuarter) {
            setIncrease(false);
        } else {
            setIncrease(true);
        };
        setDifferenceCostAbsorbtionQuarter(Math.abs(calculation.toFixed(2)));
    };

    const changeDateFormatDisplay = data => {
        let dateObj = new Date(data);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[dateObj.getMonth()];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = dateObj.getFullYear();
        const output = `${day} ${month} ${year}`;
        return output
    };

    if (loading) {
        return (
            <div className={classes.root}>
                <Grid container justifyContent="center" alignContent="center">
                    <div className={classes.loadingContainer}></div>
                    <CircularProgress color="secondary" />
                </Grid>
            </div>
        )
    } else {
        return (
            <div className={classes.root}>
                <Grid container spacing={5} direction="column">
                    <Grid item container>
                        <Grid item xs={12} className={classes.pageTitle}>
                            Product Overview
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperProductContainer}>
                            <Grid item container className={classes.selectContainer}>
                                <Grid item xs={6} sm={6}>
                                    <p className="select-label">Tribe</p>
                                    <Autocomplete
                                        id="combo-box-tribe"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeTribe(newValue);
                                        }}
                                        value={tribe}
                                        options={tribeDataList}
                                        getOptionSelected={(option, value) => option.tribeID === value}
                                        getOptionLabel={(option) => option.tribeID ? option.tribeID : option}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <p className="select-label">Product</p>
                                    <Autocomplete
                                        id="combo-box-product"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeProduct(newValue);
                                        }}
                                        value={productName}
                                        options={productDataList}
                                        getOptionSelected={(option, value) => option.product_name === value}
                                        getOptionLabel={(option) => option.product_name ? option.product_name : option}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {productOverviewData.length === 0 || productOverviewData === undefined ? (null) :
                        (<Grid item>
                            <Paper className={classes.paperAboutContainer}>
                                <Typography className={classes.textAbout}>About</Typography>
                                <Grid item container className={classes.aboutValueContainer}>
                                    <Grid item sm={4}>
                                        <Typography className={classes.textAboutTitle}>Product Description</Typography>
                                        <Typography className={classes.textAboutDesc}>{productOverviewData[0].description === "" ? "-" : productOverviewData[0].description}</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography className={classes.textAboutTitle}>Innovation Stage</Typography>
                                        <Typography className={classes.textAboutDesc}>{productOverviewData[0].current_stage === "" ? "-" : productOverviewData[0].current_stage}</Typography>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Typography className={classes.textAboutTitle}>Competitor</Typography>
                                        <Typography className={classes.textAboutDesc}>{arrayOfObjectToString(productOverviewData[0].competitor, 'competitor')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.aboutValueContainer}>
                                        <Typography className={classes.textAboutTitle}>Sales Chanel</Typography>
                                        <Typography className={classes.textAboutDesc}>{arrayOfObjectToString(productOverviewData[0].salesChannel, 'sales')}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.aboutValueContainer}>
                                        <Typography className={classes.textAboutTitle}>Prioritisation Category</Typography>
                                        <Typography className={classes.textAboutDesc}>{productOverviewData[0].prioritization === "" ? "-" : productOverviewData[0].prioritization}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className={classes.aboutValueContainer}>
                                        <Typography className={classes.textAboutTitle}>NCX Readlines</Typography>
                                        <Typography className={classes.textAboutDesc}>{arrayOfObjectToString(productOverviewData[0].ncxReadine, 'readine')}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>)
                    }
                    <Grid item container>
                        {isEmpty(revenue) ? null : revenue.value.length === 0 ? null :
                            <MultiChart chartData={newDataOnMultiChartValue(revenue.value)} isIncrease={increase} previousYear={getPreviousYear()} name="Revenue" thisYearValue={revenue.this_year_value[0].total_value ? abbreviateNumber(revenue.this_year_value[0].total_value) : ''} percentGainLoss={persentGainLoss(revenue.last_year_value[0].total_value ? revenue.last_year_value[0].total_value : 0, revenue.this_year_value[0].total_value ? revenue.this_year_value[0].total_value : 0) + "%"} />
                        }
                        {isEmpty(GMV) ? null : GMV.value.length === 0 ? null :
                            <MultiChart chartData={newDataOnMultiChartValue(GMV.value)} isIncrease={increase} previousYear={getPreviousYear()} name="GMV" thisYearValue={GMV.this_year_value[0].total_value ? abbreviateNumber(GMV.this_year_value[0].total_value) : ''} percentGainLoss={persentGainLoss(GMV.last_year_value[0].total_value ? GMV.last_year_value[0].total_value : 0, GMV.this_year_value[0].total_value ? GMV.this_year_value[0].total_value : 0) + "%"} />
                        }
                        {isEmpty(MAU) ? null : MAU.value.length === 0 ? null :
                            <MultiChart chartData={newDataOnMultiChartValue(MAU.value)} isIncrease={increase} previousYear={getPreviousYear()} name="MAU" thisYearValue={MAU.this_year_value ? abbreviateNumber(MAU.this_year_value[0].total_value) : ''} percentGainLoss={persentGainLoss(MAU.last_year_value ? MAU.last_year_value[0].total_value : 0, MAU.this_year_value ? MAU.this_year_value[0].total_value : 0) + "%"} />
                        }
                        {isEmpty(WAU) ? null : WAU.value.length === 0 ? null :
                            <MultiChart chartData={newDataOnMultiChartValue(WAU.value)} isIncrease={increase} previousYear={getPreviousYear()} name="WAU" thisYearValue={WAU.this_year_value ? abbreviateNumber(WAU.this_year_value[0].total_value) : ''} percentGainLoss={persentGainLoss(WAU.last_year_value ? WAU.last_year_value[0].total_value : 0, WAU.this_year_value ? WAU.this_year_value[0].total_value : 0) + "%"} />
                        }

                        {isEmpty(DAU) ? null : DAU.value.length === 0 ? null :
                            <MultiChart chartData={newDataOnMultiChartValue(DAU.value)} isIncrease={increase} previousYear={getPreviousYear()} name="DAU" thisYearValue={DAU.this_year_value ? abbreviateNumber(DAU.this_year_value[0].total_value) : ''} percentGainLoss={persentGainLoss(DAU.last_year_value ? DAU.last_year_value[0].total_value : 0, DAU.this_year_value ? DAU.this_year_value[0].total_value : 0) + "%"} />
                        }
                    </Grid>
                    <Grid item>
                        <Paper style={{ padding: 25 }}>
                            <Typography style={{ fontSize: 19, fontWeight: 700 }}>OKR</Typography>
                            <Grid item container style={{ marginBottom: 10 }}>
                                <Grid item xs={6} md={4}>
                                    <p className="select-label">OKR Period</p>
                                    <Autocomplete
                                        id="combo-box-period-okr"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeOkrPeriod(newValue);
                                        }}
                                        disabled={isOptionQuarterDisable}
                                        value={okrPeriod}
                                        options={okrPeriodList}
                                        getOptionSelected={(option, value) => option.quarter === value}
                                        getOptionLabel={(option) => option.quarter ? option.quarter : option}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <p className="select-label">Date</p>
                                    <Autocomplete
                                        id="combo-box-period-date"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeDate(newValue);
                                        }}
                                        disabled={isOptionDisable}
                                        value={date}
                                        options={dateList}
                                        getOptionSelected={(option, value) => option.date === value}
                                        getOptionLabel={(option) => option.date ? changeDateFormatDisplay(option.date) : changeDateFormatDisplay(option)}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <p className="select-label">Rule of Thumb Quarterly</p>
                                    <p className="text-content">{ruleOfThumb} %</p>
                                </Grid>
                            </Grid>
                            <OkrTable dataTable={okrDataTable} />
                        </Paper>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} sm={6} md={4} className={classes.papperContainer}>
                            <Paper className={classes.chartPaper}>
                                <Grid item>
                                    <Typography align="center" className={classes.textChartTitle}>Total Product Cost</Typography>
                                    <Typography align="center" className={classes.textChartValue}>{totalCostByQuarter[totalCostByQuarter.length - 1] ? abbreviateNumber(totalCostByQuarter[totalCostByQuarter.length - 1].total) : null}</Typography>
                                </Grid>
                                <LineChart chartData={arrOfObjIsEmpty(totalCostByQuarter)} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className={classes.papperContainer}>
                            <Paper className={classes.chartPaper}>
                                <Typography align="center" className={classes.textChartTitle}>Total Cost by Quarter (IDR Bn)</Typography>
                                <BarChart chartData={arrOfObjIsEmpty(totalCostByQuarter)} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} >
                            <Paper className={classes.chartPaper}>
                                <Typography align="center" className={classes.textChartTitle}>Budget Absorption this Quarter</Typography>
                                <Typography align="center" className={classes.textChartTitle}>
                                    <div style={{ width: 170, height: 170, margin: '0 auto', marginTop: 15 }}>
                                        <CircularProgressbarWithChildren
                                            value={absorpThisQuarter}
                                            styles={buildStyles({ strokeLinecap: 'butt', pathColor: '#E85757' })}
                                        >
                                            <div style={{ fontSize: 12, marginTop: -5 }}>
                                                <Typography style={{ fontSize: 36, fontWeight: 700, color: 'black' }}>{parseFloat(absorpThisQuarter.toFixed(2))}%</Typography>
                                                <Typography style={{ fontSize: 12, fontWeight: 700 }}>
                                                    {increase ? (
                                                        <img src={UpIc} alt="cunsomer digital icon" width="10" height="10" />
                                                    ) : (
                                                        <img src={DownIc} alt="cunsomer digital icon" width="10" height="10" />
                                                    )}
                                                    {differenceCostAbsorbtionQuarter ? differenceCostAbsorbtionQuarter : ''}%
                                                </Typography>
                                                <Typography style={{ fontSize: 9, fontWeight: 700 }}>(vs {getPreviousYear()})</Typography>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ProductOverview