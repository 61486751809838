import React, { useState, useEffect } from 'react'
import { Grid, Row, Col, Table, Input, InputGroup, Icon, InputPicker, Button, Modal, Alert, Dropdown } from 'rsuite'
import { jsPDF } from 'jspdf'
import Sidebar from '../components/Sidebar'
import { encryptStorage } from '../config'
import { AddKeyResult, DeleteKeyResult, ReadAllKeyResults, UpdateKeyResult } from '../services/KeyResults'
import { ListKrId, ListKrMetric, ListKrType, ListKrUnit, ListMetricType, ListProducts, ListQuarters } from '../services/List'
import { numberLimiter } from '../helper/number'

const { Column, HeaderCell, Cell, Pagination } = Table

function KeyResults() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey1, setSearchKey1] = useState('')
  const [searchKey2, setSearchKey2] = useState('')
  const [searchKey3, setSearchKey3] = useState('')
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'product',
      label: 'Product Name'
    },
    {
      value: 'quarter',
      label: 'Quarter'
    },
    {
      value: 'kr_metric',
      label: 'KR Metric'
    },
    {
      value: 'kr',
      label: 'KR ID'
    },
    {
      value: 'type',
      label: 'Type'
    },
    {
      value: 'unit',
      label: 'Unit'
    },
  ]

  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [title, setTitle] = useState('Add ')
  const [keyResultID, setKeyResultID] = useState('')
  const [product, setProduct] = useState('')
  const [productID, setProductID] = useState('')
  const [quarter, setQuarter] = useState('')
  const [krId, setKrId] = useState('')
  const [krMetric, setKrMetric] = useState('')
  const [type, setType] = useState('')
  const [unit, setUnit] = useState('')
  const [targetQuarter, setTargetQuarter] = useState('')
  const [targetFy, setTargetFy] = useState('')
  const [metricType, setMetricType] = useState('')
  const [productIdOptions, setProductIdOptions] = useState('')
  const [productNameOptions, setProductNameOptions] = useState('')
  const [listIdProduct, setListIdProduct] = useState('')
  const [listNameProduct, setListNameProduct] = useState('')
  const [listQuarters, setListQuarters] = useState('')
  const [listKrId, setListKrId] = useState('')
  const [listKrUnit, setListKrUnit] = useState('')
  const [listKrMetric, setListKrMetric] = useState('')
  const [listKrType, setListKrType] = useState('')
  const [listKrMetricType, setListKrMetricType] = useState('')
  const role = encryptStorage.getItem('role') === 'creator'

  async function getAllKeyResults() {
    const response = await ReadAllKeyResults() || { data: [], pageInfo: {} }
    setListUser(response)
  }
  async function getListProducts() {
    const resultListProductIds = []
    const resultListProductNames = []
    const listIdProduct = []
    const listNameProduct = []
    const listProducts = await ListProducts() || []

    for (const list of listProducts) {
      resultListProductIds.push({
        value: list.productID,
        label: list.productID
      })
    }
    for (const list of listProducts) {
      resultListProductNames.push({
        value: list.product_name,
        label: list.product_name
      })
    }
    for (const list of listProducts) {
      const nameProduct = list.product_name
      listIdProduct.push({
        [nameProduct]: list.productID
      })
    }
    for (const list of listProducts) {
      const idProduct = list.productID
      listNameProduct.push({
        [idProduct]: list.product_name
      })
    }

    setProductIdOptions(resultListProductIds)
    setProductNameOptions(resultListProductNames)
    setListIdProduct(listIdProduct)
    setListNameProduct(listNameProduct)
  }
  async function getListQuarters() {
    const results = []
    const lists = await ListQuarters() || []
    for (const list of lists) {
      results.push({
        value: list.quarter,
        label: list.quarter
      })
    }
    setListQuarters(results)
  }
  async function getListKrId() {
    const results = []
    const lists = await ListKrId() || []
    for (const list of lists) {
      results.push({
        value: list.kr,
        label: list.kr
      })
    }
    setListKrId(results)
  }
  async function getListKrUnit() {
    const results = []
    const lists = await ListKrUnit() || []
    for (const list of lists) {
      results.push({
        value: list.unit,
        label: list.unit
      })
    }
    setListKrUnit(results)
  }
  async function getListKrMetric() {
    const results = []
    const lists = await ListKrMetric() || []
    for (const list of lists) {
      results.push({
        value: list.kr_metric,
        label: list.kr_metric
      })
    }
    setListKrMetric(results)
  }
  async function getListKrType() {
    const results = []
    const lists = await ListKrType() || []
    for (const list of lists) {
      results.push({
        value: list.type,
        label: list.type
      })
    }
    setListKrType(results)
  }
  async function getListKrMetricType() {
    const results = []
    const lists = await ListMetricType() || []
    for (const list of lists) {
      results.push({
        value: list.metric_type,
        label: list.metric_type
      })
    }
    setListKrMetricType(results)
  }

  useEffect(() => {
    getAllKeyResults()
    getListProducts()
    getListQuarters()
    getListKrId()
    getListKrMetric()
    getListKrMetricType()
    getListKrUnit()
    getListKrType()

  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllKeyResults(data)
    setListUser(response)
  }
  async function changeLimit(e) {
    setLimit(e)
    const data = [pageInfo.page, e, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllKeyResults(data)
    setListUser(response)
  }
  async function searchData(e, searchKey) {
    let data = []
    if (searchKey === 'search1') {
      setSearchValue1(e)
      data = [pageInfo.page, limit, searchKey1, e, searchKey2, searchValue2, searchKey3, searchValue3]
    }
    if (searchKey === 'search2') {
      setSearchValue2(e)
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, e, searchKey3, searchValue3]
    }
    if (searchKey === 'search3') {
      setSearchValue3(e)
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, e]
    }
    const response = await ReadAllKeyResults(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "productID": productID,
      "productName": product,
      "quarter": quarter,
      "krID": krId,
      "krMetric": krMetric,
      "unit": unit,
      "targetQuarter": targetQuarter,
      "targetFy": targetFy,
      "type": type,
      "metricType": metricType
    }
    const response = await AddKeyResult(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllKeyResults()
  }
  async function updateData(e) {
    const id = keyResultID
    const data =
    {
      "productID": productID,
      "productName": product,
      "quarter": quarter,
      "krID": krId,
      "krMetric": krMetric,
      "unit": unit,
      "targetQuarter": targetQuarter,
      "targetFy": targetFy,
      "type": type,
      "metricType": metricType
    }
    const response = await UpdateKeyResult(id, JSON.stringify(data))
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    getAllKeyResults(dataList)
    setShow(!show)
  }
  async function deleteData(e) {
    const id = keyResultID
    const response = await DeleteKeyResult(id)
    response ? Alert.success('Delete Key Result Success') : Alert.error('Failed to delete Key result')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    getAllKeyResults(data)
  }

  function addNewData() {
    setTitle('Add ')
    setProductID('')
    setProduct('')
    setQuarter('')
    setKrId('')
    setKrMetric('')
    setType('')
    setUnit('')
    setTargetQuarter('')
    setTargetFy('')
    setMetricType('')
    setUpdate(false)
    setShow(true)
  }

  function exportToCsv(data) {
    let CsvString = "";
    data.forEach(function (RowItem, RowIndex) {
      RowItem.forEach(function (ColItem, ColIndex) {
        let makeSpace = ColItem.replace(/,/g, "");

        CsvString += makeSpace + ',';
      });
      CsvString += "\r\n";
    });
    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    let downloadElement = document.createElement("A");
    downloadElement.setAttribute("href", CsvString);
    downloadElement.setAttribute("download", "KeyResults.csv");
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }

  async function getDataInEveryPage(page) {
    const data = [page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllKeyResults(data)
    return response.data;
  }

  function handleDownloadToCSV() {
    let totalLoop = pageInfo.totalPage;
    let arrToCsvData = [["Product ID", "Product Name", "Quarter", "KR Metric", "KR ID", "Type", "Unit", "Metric Type", "Target Quarter", "Target Fy"]];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = [res.product_id, res.product, res.quarter, res.kr_metric, res.kr, res.type, res.unit, res.metric_type, res.target_quarter, res.target_fy]
            arrToCsvData.push(dataToPush)
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            exportToCsv(arrToCsvData);
          }
        })

    }
  };

  function handleDownloadPdf() {
    let totalLoop = pageInfo.totalPage;
    let result = [];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = {
              Product_ID: res.product_id,
              Product_Name: res.product,
              Quarter: res.quarter,
              KR_Metric: res.kr_metric,
              KR_ID: res.kr,
              Type: res.type ? res.type : " ",
              Unit: res.unit,
              Metric_Type: res.metric_type,
              Target_Quarter: numberLimiter(res.target_quarter),
              Target_Fy: res.target_fy ? numberLimiter(res.target_fy) : " ",
            }
            result.push(Object.assign({}, dataToPush));
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            let headers = createHeaders([
              "Product_ID", "Product_Name", "Quarter", "KR_Metric", "KR_ID", "Type", "Unit", "Metric_Type", "Target_Quarter", "Target_Fy"
            ]);
            generatePdf(result, headers);
          }
        })

    }
  };

  function createHeaders(keys) {
    let result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        name: keys[i],
        prompt: updateStringForHeader(keys[i]),
        width: 38,
        align: "center",
        padding: 0,
        fontSize: 9
      });
    }
    return result;
  }

  function updateStringForHeader(string) {
    let makeSpace = string.replace("_", " ");
    return makeSpace.toLowerCase()
  }

  function generatePdf(data, headers) {
    const pdf = new jsPDF({
      orientation: 'landscape',
      format: 'a4',
      putOnlyUsedFonts: true,
    });
    pdf.table(5, 5, data, headers, { autoSize: false, fontSize: 8 });
    pdf.save('KeyResults.pdf');
  }

  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }
  function handleProduct(e, code) {
    if (code === 'id') {
      setProductID(e)
      for (const i of listNameProduct) {
        if (i[e]) {
          setProduct(i[e])
          break
        }
      }
    }
    if (code === 'name') {
      setProduct(e)
      for (const i of listIdProduct) {
        if (i[e]) {
          setProductID(i[e])
          break
        }
      }
    }
  }
  async function handleSearchKey(e, searchKey) {
    if (searchKey === 'search1') {
      setSearchKey1(e)
      setSearchValue1('')
    }
    if (searchKey === 'search2') {
      setSearchKey2(e)
      setSearchValue2('')
    }
    if (searchKey === 'search3') {
      setSearchKey3(e)
      setSearchValue3('')
    }
    if (e === null) {
      const data = [pageInfo.page, limit, '', '', '', '', '', '']
      const response = await ReadAllKeyResults(data)
      setListUser(response)
    }
  }
  const [dataSearch, setDataSearch] = useState([])
  const [iterate, setIterate] = useState(1)
  function handleSearchData() {
    if (iterate > 3) return
    setIterate(iterate + 1)
    setDataSearch([...dataSearch, 'search' + iterate])
  }
  async function deleteSearchMenu(val) {
    setIterate(iterate - 1)
    const newDataSearch = [...dataSearch]
    newDataSearch.pop()
    setDataSearch(newDataSearch)

    let data = []
    if (val === 'search1') {
      data = [pageInfo.page, limit, '', '', '', '', '', '']
    }
    if (val === 'search2') {
      data = [pageInfo.page, limit, searchKey1, searchValue1, '', '', '', '']
    }
    if (val === 'search3') {
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, '', '']
    }
    const response = await ReadAllKeyResults(data)
    setListUser(response)
  }
  const searchMenu = (data) => {
    let searchValue = ''
    if (data === 'search1') {
      searchValue = searchValue1
    }
    if (data === 'search2') {
      searchValue = searchValue2
    }
    if (data === 'search3') {
      searchValue = searchValue3
    }
    return <Row style={{ justifyContent: 'flex-start', display: 'flex' }}>
      <InputPicker placeholder="Search By" data={searchOptions} style={{ width: 200, margin: 10 }} onChange={e => handleSearchKey(e, data)} />
      <InputGroup style={styles}>
        <Input value={searchValue} onChange={(e) => searchData(e, data)} />
      </InputGroup>
      <Button onClick={e => deleteSearchMenu(data)} style={{ marginLeft: 30, backgroundColor: 'transparent' }}>
        <Icon icon="trash-o" />
      </Button>
    </Row>
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'4-1'} openKey={'4'} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Key Results</h2>
          </Row>
          <Row>
            <Dropdown title="Export" style={{ float: 'right', fontSize: '0.8rem', height: '50%', marginLeft: 10 }}>
              <Dropdown.Item onClick={handleDownloadPdf}>Export PDF</Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadToCSV}>Export CSV</Dropdown.Item>
            </Dropdown>
            <Button onClick={handleSearchData} style={{ marginTop: 5, float: 'left' }}>Add Filters</Button>
            {role ? <Button style={{ float: 'right', marginLeft: '55%', fontSize: '0.8rem', height: '50%' }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button> : ''}
          </Row>
          {dataSearch.map((data) => searchMenu(data))}
          <Row style={{ marginTop: 25 }}>
            <Table
              virtualized
              height={500}
              data={listUser.data}
              onRowClick={data => {
                setTitle('Update ')
                setKeyResultID(data.krID)
                setProductID(data.product_id)
                setProduct(data.product)
                setQuarter(data.quarter)
                setKrId(data.kr)
                setKrMetric(data.kr_metric)
                setType(data.type)
                setUnit(data.unit)
                setTargetQuarter(data.target_quarter)
                setTargetFy(data.target_fy)
                setMetricType(data.metric_type)
                setUpdate(true)
                setShow(role ? true : false)
              }}
              loading={listUser.data ? false : true}
            >
              <Column width={100} align="center" fixed>
                <HeaderCell>Product ID</HeaderCell>
                <Cell dataKey="product_id" />
              </Column>
              <Column width={150} align="center" fixed>
                <HeaderCell>Product Name</HeaderCell>
                <Cell dataKey="product" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Quarter</HeaderCell>
                <Cell dataKey="quarter" />
              </Column>
              <Column width={300} align="center">
                <HeaderCell>KR Metric</HeaderCell>
                <Cell dataKey={"kr_metric"} />
              </Column>
              <Column width={300} align="center">
                <HeaderCell>KR ID</HeaderCell>
                <Cell dataKey="kr" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Type</HeaderCell>
                <Cell dataKey="type" />
              </Column>
              <Column width={120} align="center">
                <HeaderCell>Unit</HeaderCell>
                <Cell dataKey="unit" />
              </Column>
              <Column width={200} align="center">
                <HeaderCell>Metric Type</HeaderCell>
                <Cell dataKey="metric_type" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Target Quarter</HeaderCell>
                <Cell>{rowData => numberLimiter(rowData.target_quarter)}</Cell>
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Target Fy</HeaderCell>
                <Cell>{rowData => numberLimiter(rowData.target_fy)}</Cell>
              </Column>

            </Table>

            <Pagination
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={changeLimit}
            />
          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='xs' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('Key Result')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}> Product ID</InputGroup.Addon>
            <InputPicker placeholder=" " value={productID} data={productIdOptions} onChange={(e) => handleProduct(e, 'id')} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon> Product Name</InputGroup.Addon>
            <InputPicker placeholder=" " value={product} data={productNameOptions} onChange={(e) => handleProduct(e, 'name')} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Quarter</InputGroup.Addon>
            <InputPicker placeholder=" " value={quarter} data={listQuarters} onChange={(e) => setQuarter(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>KR ID</InputGroup.Addon>
            <InputPicker creatable block placeholder=" " value={krId} data={listKrId} onChange={(e) => setKrId(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>KR Metric</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={krMetric} data={listKrMetric} onChange={(e) => setKrMetric(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Type</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={type} data={listKrType} onChange={(e) => setType(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Unit</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={unit} data={listKrUnit} onChange={(e) => setUnit(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Metric Type</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={metricType} data={listKrMetricType} onChange={(e) => setMetricType(e)} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Target Quarter</InputGroup.Addon>
            <Input value={targetQuarter} onChange={(e) => setTargetQuarter(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Target Fy</InputGroup.Addon>
            <Input value={targetFy} onChange={(e) => setTargetFy(e)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {role ? choiceUpdate() : ''}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}



const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

const input = {
  width: '7rem'
}

export default KeyResults