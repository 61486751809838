import React, { useState, useEffect } from 'react'
import { Grid, Row, Col, Table, InputGroup, Input, Icon, InputPicker, Button, Modal, Alert } from 'rsuite'
import Sidebar from '../../components/Sidebar'
import { encryptStorage } from '../../config'
import { AddDigitalTheme, DeleteDigitalTheme, ReadAllDigitalThemes, UpdateDigitalTheme } from '../../services/digitalManagement'

const { Column, HeaderCell, Cell, Pagination } = Table

function DigitalThemes() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey, setSearchKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'digital_theme',
      label: 'Digital Theme'
    },
    {
      value: 'description',
      label: 'Description'
    }
  ]

  const [title, setTitle] = useState('Add ')
  const [digitalThemeID, setDigitalThemeID] = useState('')
  const [description, setDescription] = useState('')
  const [digitalTheme, setDigitalTheme] = useState('')
  const role = encryptStorage.getItem('role') === 'creator'

  async function getAllDigitalThemes() {
    const response = await ReadAllDigitalThemes() || { data: [], pageInfo: {} }
    setListUser(response)
  }

  useEffect(() => {
    getAllDigitalThemes()

  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey, searchValue]
    const response = await ReadAllDigitalThemes(data)
    setListUser(response)
  }
  async function changeLimit(event) {
    setLimit(event)
    const data = [pageInfo.page, event, searchKey, searchValue]
    const response = await ReadAllDigitalThemes(data)
    setListUser(response)
  }
  async function searchData(e) {
    setSearchValue(e)
    const data = [pageInfo.page, limit, searchKey, e]
    const response = await ReadAllDigitalThemes(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "description": description,
      "digitalTheme": digitalTheme
    }
    const response = await AddDigitalTheme(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllDigitalThemes()
  }
  async function updateData(e) {
    const idDigitalTheme = digitalThemeID
    const data =
    {
      "description": description,
      "digitalTheme": digitalTheme
    }
    const response = await UpdateDigitalTheme(idDigitalTheme, data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey, searchValue]
    getAllDigitalThemes(dataList)
    setShow(!show)
  }
  async function deleteData(e) {
    const idDigitalTheme = digitalThemeID
    const response = await DeleteDigitalTheme(idDigitalTheme)
    response ? Alert.success('Delete digital theme Success') : Alert.error('Failed to delete digital theme')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey, searchValue]
    getAllDigitalThemes(data)
  }

  function addNewData() {
    setTitle('Add ')
    setDescription('')
    setDigitalTheme('')
    setUpdate(false)
    setShow(true)
  }
  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }

  async function handleSearchKey(e) {
    setSearchKey(e)
    if (e === null) {
      setSearchValue('')
      const data = [pageInfo.page, limit, '', '']
      const response = await ReadAllDigitalThemes(data)
      setListUser(response)
    }
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'7-2'} openKey={'7'} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Digital Themes</h2>
          </Row>
          <Row style={{ display: 'flex' }}>
            <InputPicker placeholder="Search By" data={searchOptions} style={styles} onChange={handleSearchKey}/>
            <InputGroup style={styles}>
              <Input value={searchValue} onChange={(e) => searchData(e)} />
            </InputGroup>
            {role ? <Button style={{ float: 'right', marginLeft: '45%', fontSize: '0.8rem', height: '50%' }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button> : ''}
          </Row>
          <Row style={{ marginTop: 25, justifyContent: 'center', alignItems:'center' }}>
            <Table
              virtualized
              height={500}
              width={800}
              data={listUser.data}
              onRowClick={data => {
                setDescription(data.description)
                setDigitalTheme(data.digital_theme)
                setDigitalThemeID(data.digital_themeID)
                setTitle('Update ')
                setUpdate(true)
                setShow(true)
              }}
              loading={listUser.data ? false : true}
            >
              <Column width={200} align="center">
                <HeaderCell>Digital Theme</HeaderCell>
                <Cell dataKey="digital_theme" />
              </Column>
              <Column width={400} align="center">
                <HeaderCell>Description</HeaderCell>
                <Cell dataKey="description" />
              </Column>

            </Table>

            <Pagination
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={changeLimit}
            />
          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='xs' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('Digital Theme')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon>Digital Theme</InputGroup.Addon>
            <Input value={digitalTheme} onChange={(e) => setDigitalTheme(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon> Description</InputGroup.Addon>
            <Input componentClass="textarea" rows={5} block placeholder=" " value={description} onChange={(e) => setDescription(e)} style={{border: "none"}} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {role ? choiceUpdate() : ''}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

export default DigitalThemes