import axios from "axios";

export async function ReadAllReadiness(data = [1, 10, '', '']) {
  let [page, limit, searchKey, searchValue] = data
  searchKey = searchKey !== null ? searchKey : ''
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/ncx-readine?page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  }
  catch (e) {
    window.location.replace('/500')
  }
}

export async function AddReadine(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/ncx-readine`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function UpdateReadine(id, data) {
  try {
    const response = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/ncx-readine/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function DeleteReadine(id) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_BACKEND + `/api/information/ncx-readine/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}