import React, { useState } from 'react'
import {
  Form, Container,
  FormGroup, Header,
  FormControl,
  ControlLabel, Navbar,
  Button, Content,
  ButtonToolbar, FlexboxGrid,
  Panel, Modal
} from 'rsuite'
import axios from 'axios'
import { useHistory } from 'react-router';
import { encryptStorage } from '../config';

const headerStyles = {
  fontSize: 35,
  color: '#fff',
  fontWeight: 'bold',
  marginLeft: 10
}

function Login() {

  const [wrongInput, setWrongInput] = useState(false)
  const [name, setName] = useState();
  const [password, setPassword] = useState()
  const [load, setLoad] = useState(false)
  let history = useHistory()

  function checkLogin(name, password) {
    setLoad(true)
    axios({
      method: 'post',
      url: process.env.REACT_APP_API_BACKEND + '/api/auth/login',
      data: {
        "username": name,
        "password": password
      }
    })
      .then((res) => res.data)
      .then(
        (result) => {
          setLoad(false)
          history.push('/')
          localStorage.setItem('token', result.token)
          encryptStorage.setItem('role', result.role)
        }
        ).catch((error) => {
          setLoad(false)
          if (error) {
            setWrongInput(true)
          }
      })
  }

  return (
    <div className="show-fake-browser login-page">
      <Container >
        <Header>
          <Navbar style={{ background: '#eb5757' }}>
            <Navbar.Header>
              <div className="navbar-brand logo"><span style={headerStyles}>CX Telkom</span></div>
            </Navbar.Header>
          </Navbar>
        </Header>
        <Content>
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={8}>
              <Panel header={<h3>Login</h3>} bordered>
                <p id="count"></p>
                <Form fluid>
                  <FormGroup>
                    <ControlLabel>Username</ControlLabel>
                    <FormControl name="name" onChange={(e) => setName(e)} />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Password</ControlLabel>
                    <FormControl name="password" type="password" onChange={(e) => setPassword(e)} />
                  </FormGroup>
                  <FormGroup>
                    <ButtonToolbar>
                      <Button loading={load} type="submit" onClick={() => checkLogin(name, password)} style={{ background: '#eb5757', color: '#fff' }}>Sign in</Button>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
      <Modal show={wrongInput}>
        <Modal.Header>
          <Modal.Title>Error !</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Wrong username or password</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setWrongInput(!wrongInput)} appearance="primary">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Login