import React, { useState, useEffect } from 'react'
import { Grid, Row, Col, Table, InputGroup, Input, Icon, InputPicker, Modal, Alert, Button, Dropdown } from 'rsuite'
import { jsPDF } from "jspdf";
import Sidebar from '../components/Sidebar'
import { encryptStorage } from '../config'
import { AddProductInformation, UpdateProductInformation, ReadAllProductInformations, DeleteProductInformation } from '../services/Informations'
import { ListBusinessSegment, ListDigitalCategory, ListDigitalTheme, ListPrioritization, ListProductCategory, ListProducts, ListProductState } from '../services/List'

const { Column, HeaderCell, Cell, Pagination } = Table

function Informations() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey1, setSearchKey1] = useState('')
  const [searchKey2, setSearchKey2] = useState('')
  const [searchKey3, setSearchKey3] = useState('')
  const [searchValue1, setSearchValue1] = useState('')
  const [searchValue2, setSearchValue2] = useState('')
  const [searchValue3, setSearchValue3] = useState('')
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'pi.product_name',
      label: 'Product Name'
    },
    {
      value: 'product_category',
      label: 'Product Category'
    },
    {
      value: 'product_state',
      label: 'State'
    },
    {
      value: 'prioritization',
      label: 'Prioritization'
    },
    {
      value: 'business_segment',
      label: 'Business Segment'
    },
    {
      value: 'digital_category',
      label: 'Digital Category'
    },
    {
      value: 'digital_theme',
      label: 'Digital Theme'
    },
    {
      value: 'tribe_id',
      label: 'Tribe'
    }
  ]

  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [title, setTitle] = useState('Add ')
  const [productInformationID, setProductInformationID] = useState(0)
  const [product, setProduct] = useState('')
  const [productID, setProductID] = useState('')
  const [productCategory, setProductCategory] = useState('')
  const [state, setState] = useState('')
  const [digitalCategory, setDigitalCategory] = useState('')
  const [digitalTheme, setDigitalTheme] = useState('')
  const [description, setDescription] = useState('')
  const [segment, setSegment] = useState('')
  const [prioritization, setPrioritization] = useState('')
  const [unique, setUnique] = useState('')
  const [channel, setChannel] = useState('')
  const [custCare, setCustCare] = useState('')
  const [emailPic, setEmailPic] = useState('')
  const [picPo, setPicPo] = useState('')
  const [kontakPic, setKontakPic] = useState('')
  const [gtmReadine, setGtmReadine] = useState('')
  const [iosLink, setIosLink] = useState('')
  const [androidLink, setAndroidLink] = useState('')
  const [offAccoutnLink, setOffAccountLink] = useState('')
  const [webLink, setWebLink] = useState('')
  const [market, setMarket] = useState('')
  const [productIdOptions, setProductIdOptions] = useState('')
  const [productNameOptions, setProductNameOptions] = useState('')
  const [listIdProduct, setListIdProduct] = useState('')
  const [listNameProduct, setListNameProduct] = useState('')
  const [listBusinessSegment, setListBusinessSegment] = useState('')
  const [listPrioritization, setListPrioritization] = useState('')
  const [listDigitalCategory, setListDigitalCategory] = useState('')
  const [listProductState, setListProductState] = useState('')
  const [listProductCategory, setListProductCategory] = useState('')
  const [listDigitalTheme, setListDigitalTheme] = useState('')
  const role = encryptStorage.getItem('role') === 'creator'


  async function getAllProductInformations() {
    const response = await ReadAllProductInformations() || { data: [], pageInfo: {} }
    setListUser(response)
  }
  async function getListProducts() {
    const resultListProductIds = []
    const resultListProductNames = []
    const listIdProduct = []
    const listNameProduct = []
    const listProducts = await ListProducts() || []

    for (const list of listProducts) {
      resultListProductIds.push({
        value: list.productID,
        label: list.productID
      })
    }
    for (const list of listProducts) {
      resultListProductNames.push({
        value: list.product_name,
        label: list.product_name
      })
    }
    for (const list of listProducts) {
      const nameProduct = list.product_name
      listIdProduct.push({
        [nameProduct]: list.productID
      })
    }
    for (const list of listProducts) {
      const idProduct = list.productID
      listNameProduct.push({
        [idProduct]: list.product_name
      })
    }

    setProductIdOptions(resultListProductIds)
    setProductNameOptions(resultListProductNames)
    setListIdProduct(listIdProduct)
    setListNameProduct(listNameProduct)
  }
  async function getListBusinessSegment() {
    const results = []
    const lists = await ListBusinessSegment() || []
    for (const list of lists) {
      results.push({
        value: list.business_segment,
        label: list.business_segment
      })
    }
    setListBusinessSegment(results)
  }
  async function getListDigitalCategory() {
    const results = []
    const lists = await ListDigitalCategory() || []
    for (const list of lists) {
      results.push({
        value: list.digital_category,
        label: list.digital_category
      })
    }
    setListDigitalCategory(results)
  }
  async function getListPrioritization() {
    const results = []
    const lists = await ListPrioritization() || []
    for (const list of lists) {
      results.push({
        value: list.prioritization,
        label: list.prioritization
      })
    }
    setListPrioritization(results)
  }
  async function getListProductState() {
    const results = []
    const lists = await ListProductState() || []
    for (const list of lists) {
      results.push({
        value: list.product_state,
        label: list.product_state
      })
    }
    setListProductState(results)
  }
  async function getListProductCategory() {
    const results = []
    const lists = await ListProductCategory() || []
    for (const list of lists) {
      results.push({
        value: list.product_category,
        label: list.product_category
      })
    }
    setListProductCategory(results)
  }
  async function getListDigitalTheme() {
    const results = []
    const lists = await ListDigitalTheme() || []
    for (const list of lists) {
      results.push({
        value: list.digital_themeID,
        label: list.digital_theme
      })
    }
    setListDigitalTheme(results)
  }

  useEffect(() => {
    getAllProductInformations()
    getListProducts()
    getListBusinessSegment()
    getListDigitalCategory()
    getListPrioritization()
    getListProductState()
    getListProductCategory()
    getListDigitalTheme()

  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllProductInformations(data)
    setListUser(response)
  }
  async function changeLimit(e) {
    setLimit(e)
    const data = [pageInfo.page, e, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllProductInformations(data)
    setListUser(response)
  }
  async function searchData(e, searchKey) {
    let data = []
    if (searchKey === 'search1') {
      setSearchValue1(e)
      data = [pageInfo.page, limit, searchKey1, e, searchKey2, searchValue2, searchKey3, searchValue3]
    }
    if (searchKey === 'search2') {
      setSearchValue2(e)
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, e, searchKey3, searchValue3]
    }
    if (searchKey === 'search3') {
      setSearchValue3(e)
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, e]
    }
    const response = await ReadAllProductInformations(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "productName": product,
      "productID": productID,
      "productCategory": productCategory,
      "digitalCategory": digitalCategory,
      "digitalTheme": digitalTheme,
      "description": description,
      "webLink": webLink,
      "offAccountLink": offAccoutnLink,
      "androidLink": androidLink,
      "iosLink": iosLink,
      "channel": channel,
      "PICPO": picPo,
      "kontakPIC": kontakPic,
      "emailPIC": emailPic,
      "custCare": custCare,
      "businessSegment": segment,
      "prioritization": prioritization,
      "productState": state,
      "gtmReadiness": gtmReadine,
      "uniqueValue": unique,
      "marketPositioning": market
    }
    const response = await AddProductInformation(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllProductInformations()
  }
  async function updateData(e) {
    const id = productInformationID
    const data =
    {
      "productName": product,
      "productID": productID,
      "productCategory": productCategory,
      "digitalCategory": digitalCategory,
      "digitalTheme": digitalTheme,
      "description": description,
      "webLink": webLink,
      "offAccountLink": offAccoutnLink,
      "androidLink": androidLink,
      "iosLink": iosLink,
      "channel": channel,
      "PICPO": picPo,
      "kontakPIC": kontakPic,
      "emailPIC": emailPic,
      "custCare": custCare,
      "businessSegment": segment,
      "prioritization": prioritization,
      "productState": state,
      "gtmReadiness": gtmReadine,
      "uniqueValue": unique,
      "marketPositioning": market
    }
    const response = await UpdateProductInformation(id, data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    getAllProductInformations(dataList)
    setShow(!show)
  }
  async function deleteData(e) {
    const id = productInformationID
    const response = await DeleteProductInformation(id)
    response ? Alert.success('Delete Product Information Success') : Alert.error('Failed to delete Product Information')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    getAllProductInformations(data)
  }

  function addNewData() {
    setTitle('Add ')
    setProductID('')
    setProduct('')
    setProductCategory('')
    setState('')
    setDigitalCategory('')
    setDigitalTheme('')
    setDescription('')
    setSegment('')
    setChannel('')
    setPrioritization('')
    setUnique('')
    setCustCare('')
    setEmailPic('')
    setPicPo('')
    setKontakPic('')
    setGtmReadine('')
    setIosLink('')
    setAndroidLink('')
    setOffAccountLink('')
    setWebLink('')
    setMarket('')
    setUpdate(false)
    setShow(true)
  }

  function exportToCsv(data) {
    let CsvString = "";

    data.forEach(function (RowItem, RowIndex) {
      RowItem.forEach(function (ColItem, ColIndex) {
        // let makeSpace = ColItem.replace(/,/g, "");

        CsvString += ColItem + ',';
      });
      CsvString += "\r\n";
    });

    CsvString = "data:application/csv," + encodeURIComponent(CsvString);

    let downloadElement = document.createElement("A");

    downloadElement.setAttribute("href", CsvString);
    downloadElement.setAttribute("download", "Informations.csv");
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }

  async function getDataInEveryPage(page) {
    const data = [page, limit, searchKey1, searchValue1, searchKey2, searchValue2, searchKey3, searchValue3]
    const response = await ReadAllProductInformations(data)
    return response.data;
  }

  function handleDownloadToCSV() {
    let totalLoop = pageInfo.totalPage;
    let arrToCsvData = [["Product ID", "Product Name", "Product Category", "Product State", "Prioritization", "Segment Business", "Digital Category", "Digital Theme", "Tribe"]];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = [res.product_id, res.product_name, res.product_category, res.product_state, res.prioritization, res.business_segment, res.digital_category, res.digital_theme, res.tribe_id]
            arrToCsvData.push(dataToPush)
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            exportToCsv(arrToCsvData);
          }
        })

    }
  };

  function handleDownloadPdf() {
    let totalLoop = pageInfo.totalPage;
    let result = [];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = {
              Product_ID: res.product_id, 
              Product_Name: res.product_name, 
              Product_Category: res.product_category, 
              Product_State: res.product_state ? res.product_state : " ", 
              Prioritization: res.prioritization, 
              Segment_Business: res.business_segment ? res.business_segment : " ", 
              Digital_Category: res.digital_category, 
              Digital_Theme: res.digital_theme, 
              Tribe: res.tribe_id
            }
            result.push(Object.assign({}, dataToPush));
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            let headers = createHeaders([
              "Product_ID", "Product_Name", "Product_Category", "Product_State", "Prioritization", "Segment_Business", "Digital_Category", "Digital_Theme", "Tribe"
            ]);
            generatePdf(result, headers);
          }
        })

    }
  };

  function createHeaders(keys) {
    let result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        name: keys[i],
        prompt: updateStringForHeader(keys[i]),
        width: 45,
        align: "center",
        padding: 0,
        fontSize: 9
      });
    }
    return result;
  }

  function updateStringForHeader(string) {
    let makeSpace = string.replace("_", " ");
    return makeSpace.toLowerCase()
  }

  function generatePdf(data, headers) {
    const pdf = new jsPDF({
      orientation: 'l',
      format: 'a4',
      putOnlyUsedFonts: true
    });
    pdf.table(5, 5, data, headers, { autoSize: true, fontSize: 8.5 });
    pdf.save('Informations.pdf');
  }

  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }
  function handleProduct(e, code) {
    if (code === 'id') {
      setProductID(e)
      for (const i of listNameProduct) {
        if (i[e]) {
          setProduct(i[e])
          break
        }
      }
    }
    if (code === 'name') {
      setProduct(e)
      for (const i of listIdProduct) {
        if (i[e]) {
          setProductID(i[e])
          break
        }
      }
    }
  }
  async function handleSearchKey(e, searchKey) {
    if (searchKey === 'search1') {
      setSearchKey1(e)
      setSearchValue1('')
    }
    if (searchKey === 'search2') {
      setSearchKey2(e)
      setSearchValue2('')
    }
    if (searchKey === 'search3') {
      setSearchKey3(e)
      setSearchValue3('')
    }
    if (e === null) {
      const data = [pageInfo.page, limit, '', '', '', '', '', '']
      const response = await ReadAllProductInformations(data)
      setListUser(response)
    }
  }
  const [dataSearch, setDataSearch] = useState([])
  const [iterate, setIterate] = useState(1)
  function handleSearchData() {
    if (iterate > 3) return
    setIterate(iterate + 1)
    setDataSearch([...dataSearch, 'search' + iterate])
  }

  async function deleteSearchMenu(val) {
    setIterate(iterate - 1)
    const newDataSearch = [...dataSearch]
    newDataSearch.pop()
    setDataSearch(newDataSearch)

    let data = []
    if (val === 'search1') {
      data = [pageInfo.page, limit, '', '', '', '', '', '']
    }
    if (val === 'search2') {
      data = [pageInfo.page, limit, searchKey1, searchValue1, '', '', '', '']
    }
    if (val === 'search3') {
      data = [pageInfo.page, limit, searchKey1, searchValue1, searchKey2, searchValue2, '', '']
    }
    const response = await ReadAllProductInformations(data)
    setListUser(response)
  }
  const searchMenu = (data) => {
    let searchValue = ''
    if (data === 'search1') {
      searchValue = searchValue1
    }
    if (data === 'search2') {
      searchValue = searchValue2
    }
    if (data === 'search3') {
      searchValue = searchValue3
    }
    return <Row style={{ justifyContent: 'flex-start', display: 'flex' }}>
      <InputPicker placeholder="Search By" data={searchOptions} style={{ width: 200, margin: 10 }} onChange={e => handleSearchKey(e, data)} />
      <InputGroup style={styles}>
        <Input value={searchValue} onChange={(e) => searchData(e, data)} />
      </InputGroup>
      <Button onClick={e => deleteSearchMenu(data)} style={{ marginLeft: 30, backgroundColor: 'transparent' }}>
        <Icon icon="trash-o" />
      </Button>
    </Row>
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'3-2'} openKey={'3'} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Product Informations</h2>
          </Row>
          <Row>
            <Button onClick={handleSearchData} style={{marginTop: 5, float: 'left'}}>Add Filters</Button>
            <Dropdown title="Export" style={{ float: 'right', fontSize: '0.8rem', height: '50%', marginLeft: 10 }}>
              <Dropdown.Item onClick={handleDownloadPdf}>Export PDF</Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadToCSV}>Export CSV</Dropdown.Item>
            </Dropdown>
            {role ? <Button style={{ float: 'right', marginLeft: '55%', fontSize: '0.8rem', height: '50%' }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button> : ''}
          </Row>
          {dataSearch.map((data) => searchMenu(data))}
          <Row style={{ marginTop: 25 }}>
            <Table
              virtualized
              height={500}
              width={1220}
              data={listUser.data}
              onRowClick={data => {
                setTitle('Update ')
                setProductInformationID(data.product_informationID)
                setProductID(data.product_id)
                setProduct(data.product_name)
                setProductCategory(data.product_category)
                setState(data.product_state)
                setDigitalCategory(data.digital_category)
                setDigitalTheme(data.digital_theme_id)
                setDescription(data.description)
                setSegment(data.business_segment)
                setChannel(data.channel)
                setPrioritization(data.prioritization)
                setUnique(data.unique_value_proposition)
                setCustCare(data.cust_care)
                setEmailPic(data.email_pic)
                setPicPo(data.pic_po)
                setKontakPic(data.kontak_pic)
                setGtmReadine(data.gtm_readiness)
                setIosLink(data.ios_link)
                setAndroidLink(data.android_link)
                setOffAccountLink(data.off_account_link)
                setWebLink(data.web_link)
                setMarket(data.market_positioning)
                setUpdate(true)
                setShow(role ? true : false)
              }}
              loading={listUser.data ? false : true}
            >
              <Column width={100} align="center">
                <HeaderCell>Product ID</HeaderCell>
                <Cell dataKey="product_id" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Product Name</HeaderCell>
                <Cell dataKey="product_name" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Product Category</HeaderCell>
                <Cell dataKey="product_category" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Product State</HeaderCell>
                <Cell dataKey="product_state" />
              </Column>
              <Column width={200} align="center">
                <HeaderCell>Prioritization</HeaderCell>
                <Cell dataKey="prioritization" />
              </Column>
              <Column width={120} align="center">
                <HeaderCell>Segment Business</HeaderCell>
                <Cell dataKey="business_segment" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Digital Category</HeaderCell>
                <Cell dataKey="digital_category" />
              </Column>
              <Column width={150} align="center">
                <HeaderCell>Digital Theme</HeaderCell>
                <Cell dataKey="digital_theme" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Tribe</HeaderCell>
                <Cell dataKey="tribe_id" />
              </Column>

            </Table>

            <Pagination
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={changeLimit}
            />
          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='sm' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('Product Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}> Product ID</InputGroup.Addon>
            <InputPicker placeholder=" " size='sm' value={productID} data={productIdOptions} onChange={(e) => handleProduct(e, 'id')} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}> Product Name</InputGroup.Addon>
            <InputPicker placeholder=" " value={product} data={productNameOptions} onChange={(e) => handleProduct(e, 'name')} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Product Category</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={productCategory} data={listProductCategory} onChange={(e) => setProductCategory(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Product State</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={state} data={listProductState} onChange={(e) => setState(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Digital Category</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={digitalCategory} data={listDigitalCategory} onChange={(e) => setDigitalCategory(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Digital Theme</InputGroup.Addon>
            <InputPicker placeholder=" " value={digitalTheme} data={listDigitalTheme} onChange={(e) => setDigitalTheme(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Business Segment</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={segment} data={listBusinessSegment} onChange={(e) => setSegment(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Prioritization</InputGroup.Addon>
            <InputPicker creatable placeholder=" " value={prioritization} data={listPrioritization} onChange={(e) => setPrioritization(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Description</InputGroup.Addon>
            <Input componentClass='textarea' rows={3} value={description} onChange={(e) => setDescription(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Channel</InputGroup.Addon>
            <Input value={channel} onChange={(e) => setChannel(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={inputUniqueValue}><span>Unique Value</span> <span>Proposition</span></InputGroup.Addon>
            <Input componentClass='textarea' rows={5} value={unique} onChange={(e) => setUnique(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Cust Care</InputGroup.Addon>
            <Input value={custCare} onChange={(e) => setCustCare(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Email PIC</InputGroup.Addon>
            <Input value={emailPic} onChange={(e) => setEmailPic(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>PIC PO</InputGroup.Addon>
            <Input value={picPo} onChange={(e) => setPicPo(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Kontak PIC</InputGroup.Addon>
            <Input value={kontakPic} onChange={(e) => setKontakPic(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>GTM Readiness</InputGroup.Addon>
            <Input value={gtmReadine} onChange={(e) => setGtmReadine(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>IOS Link</InputGroup.Addon>
            <Input value={iosLink} onChange={(e) => setIosLink(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Android Link</InputGroup.Addon>
            <Input value={androidLink} onChange={(e) => setAndroidLink(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Off Account Link</InputGroup.Addon>
            <Input value={offAccoutnLink} onChange={(e) => setOffAccountLink(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Web Link</InputGroup.Addon>
            <Input value={webLink} onChange={(e) => setWebLink(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Market Positioning</InputGroup.Addon>
            <Input componentClass='textarea' rows={5} value={market} onChange={(e) => setMarket(e)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {role ? choiceUpdate() : ''}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

const input = {
  width: '9rem'
}

const inputUniqueValue = {
  width: '9rem',
  display: 'flex',
  justifyContent: 'space-evenly',
  flexDirection: 'column'
}

export default Informations