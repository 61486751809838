import axios from "axios";

export async function ReadAllTribes(data = [1, 10, '', '']) {
  let [ page, limit, searchKey, searchValue ] = data
  searchKey = searchKey !== null ? searchKey : ''
    try {
      const response = await axios({
        method: 'GET',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/tribe?page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    }
    catch(e) {
      window.location.replace('/500')
    }
}

export async function AddTribe(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/tribe`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function UpdateTribe(idTribe, data) {
  try {
    const response = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/tribe/${idTribe}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function DeleteTribe(tribeID) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/tribe/${tribeID}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch(e) {
    window.location.replace('/500')
  }
}

export async function ReadAllQuarters(data = [1, 10, '', '']) {
    let [ page, limit, searchKey, searchValue ] = data
    searchKey = searchKey !== null ? searchKey : ''
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/quarter?sort[quarterID]=0&page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        if (response.data.code === 403) {
          window.location.replace('/login')
        }
        return response.data
      }
      catch(e) {
        window.location.replace('/500')
      }
  }
  
  export async function AddQuarter(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/quarter`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: data
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    } catch(e) {
      window.location.replace('/500')
    }
  }
  
  export async function UpdateQuarter(idQuarter, data) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/quarter/${idQuarter}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: data
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    } catch(e) {
      window.location.replace('/500')
    }
  }
  
  export async function DeleteQuarter(quarterID) {
    try {
      const response = await axios({
        method: 'DELETE',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/quarter/${quarterID}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    } catch(e) {
      window.location.replace('/500')
    }
  }

  export async function ReadAllDigitalThemes(data = [1, 10, '', '']) {
    let [ page, limit, searchKey, searchValue ] = data
    searchKey = searchKey !== null ? searchKey : ''
      try {
        const response = await axios({
          method: 'GET',
          url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/theme?page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
        if (response.data.code === 403) {
          window.location.replace('/login')
        }
        return response.data
      }
      catch(e) {
        window.location.replace('/500')
      }
  }
  
  export async function AddDigitalTheme(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/theme`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: data
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    } catch(e) {
      window.location.replace('/500')
    }
  }
  
  export async function UpdateDigitalTheme(idTheme, data) {
    try {
      const response = await axios({
        method: 'PATCH',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/theme/${idTheme}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: data
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    } catch(e) {
      window.location.replace('/500')
    }
  }
  
  export async function DeleteDigitalTheme(themeID) {
    try {
      const response = await axios({
        method: 'DELETE',
        url: process.env.REACT_APP_API_BACKEND + `/api/digital-management/theme/${themeID}`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (response.data.code === 403) {
        window.location.replace('/login')
      }
      return response.data
    } catch(e) {
      window.location.replace('/500')
    }
  }