import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress'

const BorderLinearProgressGreen = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '100%'
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#6AA84F',
  },
}))(LinearProgress);

const BorderLinearProgressYellow = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '100%'
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FF9900',
  },
}))(LinearProgress);

const BorderLinearProgressRed = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: '100%'
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#E06666',
  },
}))(LinearProgress);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#E06666',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 420,
  },
});

export default function OkrTable({ dataTable, rot }) {
  const classes = useStyles();

  const percent = data => {
    let convert = data * 100;
    return parseFloat(convert.toFixed(2));
  };

  function numberLimiter(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const normalise = (data) => {

    const MIN = 0;
    const MAX = 110;
    if (data === 100 || data > 100) {
      return 100
    } else {
      let dataToProgressValue = (data - MIN) * 100 / (MAX - MIN);
      return parseFloat(dataToProgressValue);
    }

  }
  return (
    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell component="th" scope="row">
              Objective And Key Result
            </StyledTableCell>
            <StyledTableCell align="center">uic</StyledTableCell>
            <StyledTableCell align="center">type</StyledTableCell>
            <StyledTableCell align="center">start</StyledTableCell>
            <StyledTableCell align="center">current</StyledTableCell>
            <StyledTableCell align="center">target</StyledTableCell>
            <StyledTableCell align="center">unit</StyledTableCell>
            <StyledTableCell align="center">progress</StyledTableCell>
            <StyledTableCell align="center">ach</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.map((row, index) => (
            <StyledTableRow key={index}>
              <TableCell component="th" scope="row">
                {row.kr_metric}
              </TableCell>
              <TableCell align="center">{row.tribe_id}</TableCell>
              <TableCell align="center">{row.type}</TableCell>
              <TableCell align="center">{numberLimiter(row.start)}</TableCell>
              <TableCell align="center">
                {numberLimiter(row.current)}
              </TableCell>
              <TableCell align="center">
                {numberLimiter(row.target_quarter)}
              </TableCell>
              <TableCell align="center">{row.unit}</TableCell>
              <TableCell align="center">{
                percent(row.ach) >= 100 ? <BorderLinearProgressGreen variant="determinate" value={normalise(percent(row.ach))} /> : percent(row.ach) >= rot ? <BorderLinearProgressYellow variant="determinate" value={normalise(percent(row.ach))} /> : <BorderLinearProgressRed variant="determinate" value={normalise(percent(row.ach))} />
              }
              </TableCell>
              <TableCell align="center">{percent(row.ach)}%</TableCell>
            </StyledTableRow>
          ))}
          {dataTable.length === 0 ? (
            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell rowSpan={3} />
              <TableCell rowSpan={3} />
              <TableCell align="center">Data is Empty</TableCell>
            </TableRow>
          ) : (null)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}