import axios from "axios";

export default async function ReadAllUsers(data = [1, 10, '', '']) {
  let [page, limit, searchKey, searchValue] = data
  searchKey = searchKey !== null ? searchKey : ''
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/users?page=${page}&limit=${limit}&search[${searchKey}]=${searchValue}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  }
  catch (e) {
    window.location.replace('/500')
  }
}

export async function AddUser(data) {
  try {
    const response = await axios({
      method: 'POST',
      url: process.env.REACT_APP_API_BACKEND + `/api/auth/register`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function UpdateUser(id, data) {
  try {
    const userDetail = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/users/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: data
    })
    const changeRole = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/auth/c/role`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: {
        "username": data.username,
        "role": data.role
      }
    })
    const resp = {
      userDetail: userDetail.data,
      changeRole: changeRole.data
    }
    if (resp.userDetail.code === 403 || resp.changeRole.code === 403) {
      window.location.replace('/login')
    }
    return resp
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function UpdatePassword(id, data) {
  try {
    const response = await axios({
      method: 'PATCH',
      url: process.env.REACT_APP_API_BACKEND + `/api/auth/reset-password`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      data: {
        "username": data.username,
        "password": data.password
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}

export async function DeleteUser(id) {
  try {
    const response = await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_API_BACKEND + `/api/users/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500')
  }
}