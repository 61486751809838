import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
      return (
          <div className="custom-tooltip">
              <p className="tooltip-label">{label}</p>
              <p className="tooltip-value">{`value : ${numberLimiter(payload[0].value)}`}</p>
          </div>
      );
  }

  return null;
};

const numberLimiter = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export default class Example extends PureComponent {

  render() {
    const dataChart = this.props.chartData;

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={dataChart} margin={{ top: 20, right: 0, bottom: 0, left: 0 }}>
          <XAxis dataKey="quarter" />
          <YAxis type="number" domain={[0, dataChart[dataChart.length - 1] ? parseInt(dataChart[dataChart.length - 1].budget) : 0]} hide={true}/>
          <Tooltip content={<CustomTooltip/>}/>
          <Bar dataKey="budget" fill="#E85757" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
