import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const total = (cv, pv, bmv, mv, rv) => {
  return cv + pv + bmv + mv + rv
}

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function PortofolioTable({ dataTable }) {
  const classes = useStyles();
  let sumTotalRowData = 0;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th" scope="row">
              Theme
            </TableCell>
            <TableCell align="left">Tribe</TableCell>
            <TableCell align="center">Product</TableCell>
            <TableCell align="center">CV</TableCell>
            <TableCell align="center">PV</TableCell>
            <TableCell align="center">BMV</TableCell>
            <TableCell align="center">MV</TableCell>
            <TableCell align="center">RV</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            dataTable.map((res) => (
              res.development.map((row, index) => {
                let rowTotal = total(row.cv, row.pv, row.bmv, row.mv, row.rv)
                sumTotalRowData = sumTotalRowData + rowTotal;

                return <StyledTableRow key={index}>
                  <TableCell component="th" scope="row">
                    {index === 0 ? res.digital_theme : ''}
                  </TableCell>
                  <TableCell align="left">{row.tribe}</TableCell>
                  <TableCell align="center">{row.product}</TableCell>
                  <TableCell align="center">{row.cv}</TableCell>
                  <TableCell align="center">{row.pv}</TableCell>
                  <TableCell align="center">{row.bmv}</TableCell>
                  <TableCell align="center">{row.mv}</TableCell>
                  <TableCell align="center">{row.rv}</TableCell>
                  <TableCell align="center">{rowTotal}</TableCell>
                </StyledTableRow>
              })
            ))}
          <StyledTableRow>
            <TableCell component="th" scope="row">
              Total
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="center">{sumTotalRowData}</TableCell>
          </StyledTableRow>
          {
            dataTable.length === 0 ? (
              <StyledTableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell align="center">Data is Empty</TableCell>
              </StyledTableRow>
            ) : (null)
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}