import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "rsuite";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Typography,
    Grid,
    Paper,
    CircularProgress,
    Tooltip
} from '@material-ui/core';
import PortofolioTable from '../components/portofolioTable';
import DigitalThemeComponent from '../components/DigitalTheme';
import { getDigitalTheme, getDigitalProductOverview } from '../services/PortofolioOverview';
import '../App.css';
import 'react-circular-progressbar/dist/styles.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
    },
    paper: {
        paddingLeft: 20,
        color: theme.palette.text.secondary,
    },
    user: {
        borderLeft: '1px solid #DFE0EB'
    },
    progressContainer: {
        marginBottom: 20,
    },
    cardSmall: {
        width: 136,
        height: 124,
        marginRight: 16,
        marginBottom: 15,
        padding: 15,
    },
    cardSmall2: {
        width: 136,
        height: 124,
        marginRight: 16,
        marginBottom: 15,
        padding: '15px 7px 15px 7px',
    },
    cardLarge: {
        [theme.breakpoints.up('xs')]: {
            height: 263,
        },
        [theme.breakpoints.down('xs')]: {
            height: 300,
        },
        height: 263,
        marginRight: 16,
        marginBottom: 15,
        padding: 30,
    },
    cardDigitalTheme: {
        width: 269,
        height: 263,
        marginRight: 16,
        marginBottom: 16,
        padding: '0 20px 0 20px',
    },
    gridBae: {
        border: '2px solid black'
    },
    gridBae1: {
        border: '2px solid red'
    },
    textTitle: {
        fontWeight: 700,
        fontSize: 14,
        color: '#9FA2B4',
    },
    textValue: {
        fontSize: 36,
        fontWeight: 700,
        marginTop: 15,
    },
    textValueCRMsmall: {
        fontSize: 9,
        fontWeight: 700,
        color: '#9FA2B4',
    },
    pageTitle: {
        fontSize: 24,
        fontWeight: 700,
    },
    textChart: {
        fontSize: 12,
        fontWeight: 700,
    },

}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const TooltipContent = ({ content, parameter }) => {
    return (
        <div>
            <ul>
                {
                    content.map((res, index) => {
                        return <li key={index}>{parameter === "tribe" ? res.tribeID : parameter === "ncxReadine" ? res.ncx_readine : parameter === "digitalTheme" ? res.digital_theme : res.product_name}</li>
                    })
                }
            </ul>
        </div>
    );
};

const PortofolioOverview = () => {
    const classes = useStyles();

    const [ict, setIct] = useState('');
    const [Platform, setPlatform] = useState('');
    const [Addons, setAddons] = useState('');
    const [Digitization, setDigitization] = useState('');
    const [Tribe, setTribe] = useState('');
    const [Valuation, setValuation] = useState('');
    const [DigitalThemeTotal, setDigitalThemeTotal] = useState('');
    const [crmIntegration, setCrmIntegration] = useState('');
    const [loading, setLoading] = useState(true);
    const [TotalProductByCategory, setTotalProductByCategory] = useState({});
    const [digitalTheme, setDigitalTheme] = useState([]);
    const [tooltipDesc, setTooltipDesc] = useState({});

    const history = useHistory();
    const { tokenParam } = useParams();
    if (tokenParam && tokenParam) {
        localStorage.setItem('token', tokenParam);
    }

    useEffect(() => {
        const getData = async () => {
            try {
                await setLoading(true)
                await getDataDigitalProductOverview();
                await digitalThemeData();
                await setLoading(false)
            } catch (err) {
                Alert.error("Failed to get Data")
                setLoading(false)
                history.push("/500")
            }
        }
        getData();
    }, [history])

    const getDataDigitalProductOverview = async () => {
        let dataDigitalProduct = await getDigitalProductOverview();
        let productOverview = dataDigitalProduct.data[0];
        setAddons(productOverview.addons);
        setValuation(productOverview.valuation);
        setIct(productOverview.ict);
        setDigitization(productOverview.digitization);
        setPlatform(productOverview.platform);
        setDigitalThemeTotal(productOverview.digitalTheme);
        setTribe(productOverview.tribe);
        setCrmIntegration(productOverview.ncxReadine);
        setTotalProductByCategory(dataDigitalProduct.data[1].products);
        setTooltipDesc(dataDigitalProduct.data[2].tooltipDesc);
    }

    const digitalThemeData = async () => {
        let dThemeData = await getDigitalTheme();
        setDigitalTheme(dThemeData.data);
    }

    if (loading) {
        return (
            <div className={classes.root}>
                <Grid container justifyContent="center" alignContent="center">
                    <div className={classes.loadingContainer}></div>
                    <CircularProgress color="secondary" />
                </Grid>
            </div>
        )
    } else {
        return (
            <div className={classes.root}>
                <Grid item container spacing={5} direction="column" xs={12}>
                    <Grid item container>
                        <Grid item xs={12} className={classes.pageTitle}>
                            Portofolio Overview
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <h4 className="sub-title">Digital Product Overview</h4>
                        <Grid item container xs={12}>
                            <Grid container xs={12} md={4} item justifyContent="center" alignContent="center">
                                <Paper className={classes.cardLarge}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Total Product by category
                                    </Typography>
                                    <Grid item container>
                                        <div style={{ width: 140, height: 140, margin: '0 auto', marginTop: 15, marginBottom: 15 }}>
                                            <CircularProgressbarWithChildren
                                                value={TotalProductByCategory.nonDigitization}
                                                maxValue={TotalProductByCategory.totalProduct}
                                                styles={buildStyles({ strokeLinecap: 'butt', pathColor: '#E85757', trailColor: "#EED7C4" })}
                                            >
                                                <div style={{ fontSize: 12, marginTop: -5 }}>
                                                    <Typography style={{ fontSize: 36, fontWeight: 700, color: 'black' }}>{TotalProductByCategory.totalProduct}</Typography>
                                                    <strong style={{ fontWeight: 700 }}>Product</strong>
                                                </div>
                                            </CircularProgressbarWithChildren>
                                        </div>
                                        <Grid item style={{ margin: 'auto 0', marginLeft: 15 }}>
                                            <Grid item container>
                                                <div style={{ width: 13, height: 13, borderRadius: 3, background: "#E85757", marginTop: 3, marginRight: 5 }}></div>
                                                <div style={{ marginRight: 10 }}>Digitalization : <strong>{TotalProductByCategory.nonDigitization}</strong></div>
                                            </Grid>
                                            <Grid item container>
                                                <div style={{ width: 13, height: 13, borderRadius: 3, background: "#EED7C4", marginTop: 3, marginRight: 5 }}></div>
                                                <div style={{}}>Digitization : <strong>{TotalProductByCategory.digitization}</strong></div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={8} container justifyContent="center" alignContent="center">
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Valuation
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.valuation} />}
                                    >
                                        <Typography align="center" className={classes.textValue}>{Valuation}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        ICT
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.ict} />}
                                    >
                                    <Typography align="center" className={classes.textValue}>{ict}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Add-ons
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.addons} />}
                                    >
                                    <Typography align="center" className={classes.textValue}>{Addons}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Digitization
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.digitization} />}
                                    >
                                    <Typography align="center" className={classes.textValue}>{Digitization}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Platform
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.platform} />}
                                    >
                                    <Typography align="center" className={classes.textValue}>{Platform}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Theme
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.digitalTheme} parameter={"digitalTheme"}/>}
                                    >
                                    <Typography align="center" className={classes.textValue}>{DigitalThemeTotal}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall}>
                                    <Typography align="center" className={classes.textTitle} >
                                        Tribe Unit
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.tribe} parameter={"tribe"}/>}
                                    >
                                    <Typography align="center" className={classes.textValue}>{Tribe}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                                <Paper className={classes.cardSmall2}>
                                    <Typography align="center" className={classes.textTitle} >
                                        CRM Integration
                                    </Typography>
                                    <HtmlTooltip
                                        title={<TooltipContent content={tooltipDesc.ncxReadine} parameter={"ncxReadine"}/>}
                                    >
                                    <Typography align="center" className={classes.textValue}>{crmIntegration}</Typography>
                                    </HtmlTooltip>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <h4 className="sub-title">Digital Theme</h4>
                        <Grid item container justifyContent="center" alignContent="center">
                            {
                                digitalTheme.map((res, index) => {
                                    return <DigitalThemeComponent data={res} keyUnique={index} />
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <h4 className="sub-title">Digital Product Development Stage​</h4>
                        <PortofolioTable dataTable={digitalTheme} />
                    </Grid>
                </Grid>
            </div>
        );
    }

}

export default PortofolioOverview