import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Grid, Row, Col, Nav } from 'rsuite';
import { useHistory } from 'react-router-dom';

import OkrOverview from './OkrOverview'
import ProductOverview from './ProductOverview'
import PortofolioOverview from './PortofolioOverview'

function Home() {
  let history = useHistory()
  const [okr, setOkr] = useState(false)
  const [portofolio, setPortofolio] = useState(false)
  const [product, setProduct] = useState(false)
  const [row, setRow] = useState('')

  useEffect(() => {
    let isMounted = true;
    handleOkr()
    if (!localStorage.getItem('token')) {
      history.push('/login')
    }
    return () => { isMounted = false }
  }, [history])

  function handleOkr() {
    setOkr(true)
    setPortofolio(false)
    setProduct(false)
    setRow(<OkrOverview/>)
  }
  function handlePortofolio() {
    setOkr(false)
    setPortofolio(true)
    setProduct(false)
    setRow(<PortofolioOverview/>)
  }
  function handleProduct() {
    setOkr(false)
    setPortofolio(false)
    setProduct(true)
    setRow(<ProductOverview/>)
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={"1"} openKey={''} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Dashboard</h2>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Nav appearance="tabs" justified>
              <Nav.Item active={okr} onSelect={handleOkr} >OKR Overview</Nav.Item>
              <Nav.Item active={portofolio} onSelect={handlePortofolio}>Portofolio Overview</Nav.Item>
              <Nav.Item active={product} onSelect={handleProduct}>Product Overview</Nav.Item>
            </Nav>
          </Row>
          <Row>
            {row}
          </Row>
        </Col>
      </Grid>
    </>
  )
}

export default Home