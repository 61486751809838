import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Box,Typography } from '@material-ui/core';
import comsumerDigitalIcon from '../images/ic-consumer-digital.png';
import ecosystemIcon from '../images/ic-ecosystem.png';
import enterpriseDigitalIcon from '../images/ic-enterprise-digital.png';
import horizontalPlatformIcon from '../images/ic-horizontal-platform.png';

const useStyles = makeStyles({
    cardDigitalTheme: {
        width: 269,
        height: 263,
        marginRight: 16,
        marginBottom: 16,
        padding: '0 20px 0 20px',
    },
    textChart: {
        fontSize: 12,
        fontWeight: 700,
    },
});

const chooseIcon = (digitalThemeName) => {
    if (digitalThemeName === "Ecosystem/Vertical") {
        return ecosystemIcon
    } else if (digitalThemeName === "Horizontal Platform") {
        return horizontalPlatformIcon
    } else if (digitalThemeName === "Enterprise Digital") {
        return enterpriseDigitalIcon
    } else if (digitalThemeName === "Consumer Digital") {
        return comsumerDigitalIcon
    } else {
        return null
    }
}

const barWidth = (value) => {
    let total = 50;
    let presentase = value / total;
    let width = 70 * presentase;

    return width
}

export default function DigitalThemeCard({ data, keyUnique }) {
    const classes = useStyles();

    return (
        <Paper className={classes.cardDigitalTheme}>
            <Grid container justifyContent="center">
                <h4>{data.digital_theme}</h4>
            </Grid>
            <Grid container style={{ marginTop: 20 }}>
                <Grid item xs={4}>
                    <img src={chooseIcon(data.digital_theme)} alt="cunsomer digital icon" width="60" height="60" />
                </Grid>
                <Grid item container xs={8}>
                    <Grid item>
                        <Box className={classes.textChart} display="flex" justifyContent="flex-end">Product</Box>
                        <Box className={classes.textChart} display="flex" justifyContent="flex-end">Tribe</Box>
                    </Grid>
                    <Grid item>
                        <Box display="flex" flexDirection="row" style={{ fontSize: 12, fontWeight: 700 }}>
                            <Box style={{ width: barWidth(data.digital.product), height: 10, margin: "1px 5px 5px 5px" }} bgcolor="#E85757" />
                            {data.digital.product}
                        </Box>
                        <Box display="flex" flexDirection="row" style={{ fontSize: 12, fontWeight: 700 }}>
                            <Box style={{ width: barWidth(data.digital.tribe), height: 10, margin: "1px 5px 5px 5px" }} bgcolor="#EED7C4" />
                            {data.digital.tribe}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Paper elevation={0} style={{ marginTop: 30 }}>
                    <Typography className="text-digital-theme">{data.description}​</Typography>
                </Paper>
            </Grid>
        </Paper>
    );
}