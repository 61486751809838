import React, { useState, useEffect } from 'react'
import { Grid, Row, Col, Table, InputGroup, Input, Icon, InputPicker, Button, Modal, Alert } from 'rsuite'
import Sidebar from '../../components/Sidebar'
import { encryptStorage } from '../../config'
import { AddTribe, DeleteTribe, ReadAllTribes, UpdateTribe } from '../../services/digitalManagement'

const { Column, HeaderCell, Cell, Pagination } = Table

function Tribes() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey, setSearchKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'tribeID',
      label: 'tribeID'
    },
    {
      value: 'tribe',
      label: 'tribe'
    }
  ]

  const [title, setTitle] = useState('Add ')
  const [tribeID, setTribeID] = useState('')
  const [tribe, setTribe] = useState('')
  const role = encryptStorage.getItem('role') === 'creator'

  async function getAllTribes() {
    const response = await ReadAllTribes() || { data: [], pageInfo: {} }
    setListUser(response)
  }

  useEffect(() => {
    getAllTribes()

  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey, searchValue]
    const response = await ReadAllTribes(data)
    setListUser(response)
  }
  async function changeLimit(event) {
    setLimit(event)
    const data = [pageInfo.page, event, searchKey, searchValue]
    const response = await ReadAllTribes(data)
    setListUser(response)
  }
  async function searchData(e) {
    setSearchValue(e)
    const data = [pageInfo.page, limit, searchKey, e]
    const response = await ReadAllTribes(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "tribeID": tribeID,
      "tribe": tribe
    }
    const response = await AddTribe(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllTribes()
  }
  async function updateData(e) {
    const idTribe = tribeID
    const data =
    {
      "tribeID": tribeID,
      "tribe": tribe
    }
    const response = await UpdateTribe(idTribe, data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey, searchValue]
    getAllTribes(dataList)
    setShow(!show)
  }
  async function deleteData(e) {
    const idTribe = tribeID
    const response = await DeleteTribe(idTribe)
    response ? Alert.success('Delete tribe Success') : Alert.error('Failed to delete tribe')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey, searchValue]
    getAllTribes(data)
  }

  function addNewData() {
    setTitle('Add ')
    setTribeID('')
    setTribe('')
    setUpdate(false)
    setShow(true)
  }
  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }
async function handleSearchKey(e) {
    setSearchKey(e)
    if (e === null) {
      setSearchValue('')
      const data = [pageInfo.page, limit, '', '']
      const response = await ReadAllTribes(data)
      setListUser(response)
    }
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'7-1'} openKey={'7'} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Tribes</h2>
          </Row>
          <Row style={{ display: 'flex' }}>
            <InputPicker placeholder="Search By" data={searchOptions} style={styles} onChange={handleSearchKey}/>
            <InputGroup style={styles}>
              <Input value={searchValue} onChange={(e) => searchData(e)} />
            </InputGroup>
            {role ? <Button style={{ float: 'right', marginLeft: '45%', fontSize: '0.8rem', height: '50%' }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button> : ''}
          </Row>
          <Row style={{ marginTop: 25, justifyContent: 'center', alignItems:'center' }}>
            <Table
              virtualized
              height={500}
              width={800}
              data={listUser.data}
              onRowClick={data => {
                setTribe(data.tribe)
                setTribeID(data.tribeID)
                setTitle('Update ')
                setUpdate(true)
                setShow(true)
              }}
              loading={listUser.data ? false : true}
            >
              <Column width={200} align="center">
                <HeaderCell>Tribe ID</HeaderCell>
                <Cell dataKey="tribeID" />
              </Column>
              <Column width={400} align="center">
                <HeaderCell>Tribe</HeaderCell>
                <Cell dataKey="tribe" />
              </Column>

            </Table>

            <Pagination
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={changeLimit}
            />
          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='xs' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('Tribe')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={{width: "7rem"}}> Tribe ID</InputGroup.Addon>
            <Input disabled={update} block placeholder=" " value={tribeID} onChange={(e) => setTribeID(e)} style={{border: "none"}} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon>Tribe</InputGroup.Addon>
            <Input value={tribe} onChange={(e) => setTribe(e)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {role ? choiceUpdate() : ''}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

export default Tribes