import React, { PureComponent } from 'react';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  LabelList,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
      return (
          <div className="custom-tooltip">
              <p className="tooltip-label">{label}</p>
              <p className="tooltip-value">{`value : ${numberLimiter(payload[0].value)}`}</p>
          </div>
      );
  }

  return null;
};

const numberLimiter = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export default class Example extends PureComponent {

  render() {
    let dataChart = this.props.chartData;
    return (
      <ResponsiveContainer width="100%" height="72%">
        <LineChart data={dataChart} margin={{ top: 30, right: 30, bottom: 30, left: 30 }}>
          <XAxis dataKey="quarter" hide={true} />
          <YAxis type="number" domain={[0, dataChart[dataChart.length - 1] ? parseInt(dataChart[dataChart.length - 1].budget) : 0]} hide={true}/>
          <Tooltip content={<CustomTooltip/>}/>
          <Line type="monotone" dot={false} dataKey="budget" stroke="#E85757" strokeWidth={2} >
            <LabelList dataKey="quarter" position="bottom" />
          </Line>
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
