import React, { useState, useEffect } from 'react'
import { Grid, Row, Col, Table, InputGroup, Input, Icon, InputPicker, Button, Modal, Alert, Dropdown } from 'rsuite'
import { jsPDF } from 'jspdf'
import Sidebar from '../components/Sidebar'
import { encryptStorage } from '../config'
import { ListProducts } from '../services/List'
import { ReadAllSalesChannels, AddChannel, UpdateChannel, DeleteChannel } from '../services/SalesChannels'

const { Column, HeaderCell, Cell, Pagination } = Table

function Readiness() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey, setSearchKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'product_name',
      label: 'Product Name'
    },
    {
      value: 'sales_channel',
      label: 'Sales Channel'
    }
  ]
  const [show, setShow] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [title, setTitle] = useState('Add ')
  const [channelID, setChannelID] = useState('')
  const [product, setProduct] = useState('')
  const [productID, setProductID] = useState('')
  const [channel, setChannel] = useState('')
  const [productIdOptions, setProductIdOptions] = useState('')
  const [productNameOptions, setProductNameOptions] = useState('')
  const [listIdProduct, setListIdProduct] = useState('')
  const [listNameProduct, setListNameProduct] = useState('')
  const role = encryptStorage.getItem('role') === 'creator'

  async function getAllSalesChannel() {
    const response = await ReadAllSalesChannels() || { data: [], pageInfo: {} }
    setListUser(response)
  }
  async function getListProducts() {
    const resultListProductIds = []
    const resultListProductNames = []
    const listIdProduct = []
    const listNameProduct = []
    const listProducts = await ListProducts() || []

    for (const list of listProducts) {
      resultListProductIds.push({
        value: list.productID,
        label: list.productID
      })
    }
    for (const list of listProducts) {
      resultListProductNames.push({
        value: list.product_name,
        label: list.product_name
      })
    }
    for (const list of listProducts) {
      const nameProduct = list.product_name
      listIdProduct.push({
        [nameProduct]: list.productID
      })
    }
    for (const list of listProducts) {
      const idProduct = list.productID
      listNameProduct.push({
        [idProduct]: list.product_name
      })
    }

    setProductIdOptions(resultListProductIds)
    setProductNameOptions(resultListProductNames)
    setListIdProduct(listIdProduct)
    setListNameProduct(listNameProduct)
  }

  useEffect(() => {
    getAllSalesChannel()
    getListProducts()

  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey, searchValue]
    const response = await ReadAllSalesChannels(data)
    setListUser(response)
  }
  async function changeLimit(e) {
    setLimit(e)
    const data = [pageInfo.page, e, searchKey, searchValue]
    const response = await ReadAllSalesChannels(data)
    setListUser(response)
  }
  async function searchData(e) {
    setSearchValue(e)
    const data = [pageInfo.page, limit, searchKey, e]
    const response = await ReadAllSalesChannels(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "productName": product,
      "productID": productID,
      "salesChannel": channel
    }
    const response = await AddChannel(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllSalesChannel()
  }
  async function updateData(e) {
    const id = channelID
    const data =
    {
      "productName": product,
      "productID": productID,
      "salesChannel": channel
    }
    const response = await UpdateChannel(id, data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey, searchValue]
    getAllSalesChannel(dataList)
    setShow(!show)
  }
  async function deleteData(e) {
    const id = channelID
    const response = await DeleteChannel(id)
    response ? Alert.success('Delete sales channel Success') : Alert.error('Failed to delete sales channel')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey, searchValue]
    getAllSalesChannel(data)
  }

  function addNewData() {
    setTitle('Add ')
    setProductID('')
    setProduct('')
    setChannel('')
    setUpdate(false)
    setShow(true)
  }

  function exportToCsv(data) {
    let CsvString = "";

    data.forEach(function (RowItem, RowIndex) {
      RowItem.forEach(function (ColItem, ColIndex) {
        let makeSpace = ColItem.replace(/,/g, "");

        CsvString += makeSpace + ',';
      });
      CsvString += "\r\n";
    });

    CsvString = "data:application/csv," + encodeURIComponent(CsvString);

    let downloadElement = document.createElement("A");

    downloadElement.setAttribute("href", CsvString);
    downloadElement.setAttribute("download", "SalesChannels.csv");
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }

  async function getDataInEveryPage(page) {
    const data = [page, limit, searchKey, searchValue]
    const response = await ReadAllSalesChannels(data)
    return response.data;
  }

  function handleDownloadToCSV() {
    let totalLoop = pageInfo.totalPage;
    let arrToCsvData = [["Product ID", "Product Name", "Sales Channel"]];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = [res.product_id, res.product_name, res.sales_channel]
            arrToCsvData.push(dataToPush)
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            exportToCsv(arrToCsvData);
          }
        })

    }
  };

  function handleDownloadPdf() {
    let totalLoop = pageInfo.totalPage;
    let result = [];
    for (let index = 0; index < totalLoop; index++) {
      getDataInEveryPage(index + 1)
        .then(dataOnPage => {
          dataOnPage.forEach(res => {
            let dataToPush = {
              Product_ID: res.product_id ? res.product_id : "",
              Product_Name: res.product_name ? res.product_name : "",
              Sales_Channel: res.sales_channel ? res.sales_channel : "",
            }
            result.push(Object.assign({}, dataToPush));
          });
        })
        .then(() => {
          if (index === totalLoop - 1) {
            let headers = createHeaders([
              "Product_ID", "Product_Name", "Sales_Channel"

            ]);
            generatePdf(result, headers);
          }
        })

    }
  };

  function createHeaders(keys) {
    let result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        name: keys[i],
        prompt: updateStringForHeader(keys[i]),
        width: 45,
        align: "center",
        padding: 0,
        fontSize: 9
      });
    }
    return result;
  }

  function updateStringForHeader(string) {
    let makeSpace = string.replace("_", " ");
    return makeSpace.toLowerCase()
  }

  function generatePdf(data, headers) {
    const pdf = new jsPDF({
      orientation: 'p',
      format: 'a4',
      putOnlyUsedFonts: true
    });
    pdf.table(5, 5, data, headers, { autoSize: true, fontSize: 8.5, marginTop: 10 });
    pdf.save('SalesChannels.pdf');
  }

  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }
  function handleProduct(e, code) {
    if (code === 'id') {
      setProductID(e)
      for (const i of listNameProduct) {
        if (i[e]) {
          setProduct(i[e])
          break
        }
      }
    }
    if (code === 'name') {
      setProduct(e)
      for (const i of listIdProduct) {
        if (i[e]) {
          setProductID(i[e])
          break
        }
      }
    }
  }
  async function handleSearchKey(e) {
    setSearchKey(e)
    if (e === null) {
      setSearchValue('')
      const data = [pageInfo.page, limit, '', '']
      const response = await ReadAllSalesChannels(data)
      setListUser(response)
    }
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'3-6'} openKey={'3'} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Sales Channels</h2>
          </Row>
          <Row style={{ display: 'flex' }}>
            <InputPicker placeholder="Search By" data={searchOptions} style={styles} onChange={handleSearchKey} />
            <InputGroup style={styles}>
              <Input value={searchValue} onChange={(e) => searchData(e)} />
            </InputGroup>
            {role ? <Button style={{ float: 'right', marginLeft: '18%', fontSize: '0.8rem', height: '50%', margin: 10 }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button> : ''}
            <Dropdown title="Export" style={{ float: 'right', fontSize: '0.8rem', height: '50%', margin: 10 }}>
              <Dropdown.Item onClick={handleDownloadPdf}>Export PDF</Dropdown.Item>
              <Dropdown.Item onClick={handleDownloadToCSV}>Export CSV</Dropdown.Item>
            </Dropdown>
          </Row>
          <Row style={{ marginTop: 25 }}>
            <Table
              virtualized
              height={500}
              width={600}
              data={listUser.data}
              onRowClick={data => {
                setProductID(data.product_id)
                setProduct(data.product_name)
                setChannel(data.sales_channel)
                setChannelID(data.sales_channelID)
                setTitle('Update ')
                setUpdate(true)
                setShow(role ? true : false)
              }}
              loading={listUser.data ? false : true}
            >
              <Column width={200} align="center">
                <HeaderCell>Product ID</HeaderCell>
                <Cell dataKey="product_id" />
              </Column>
              <Column width={200} align="center">
                <HeaderCell>Product Name</HeaderCell>
                <Cell dataKey="product_name" />
              </Column>
              <Column width={200} align="center">
                <HeaderCell>Sales Channel</HeaderCell>
                <Cell dataKey="sales_channel" />
              </Column>

            </Table>

            <Pagination
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={changeLimit}
            />
          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='xs' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('Sales Channel')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={{ width: "7rem" }}> Product ID</InputGroup.Addon>
            <InputPicker placeholder=" " value={productID} data={productIdOptions} onChange={(e) => handleProduct(e, 'id')} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon> Product Name</InputGroup.Addon>
            <InputPicker placeholder=" " value={product} data={productNameOptions} onChange={(e) => handleProduct(e, 'name')} style={{ border: "none" }} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon>Current Stage</InputGroup.Addon>
            <Input value={channel} onChange={(e) => setChannel(e)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          {role ? choiceUpdate() : ''}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

export default Readiness