import axios from "axios";

export async function getTribeList() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/list-tribe`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}

export async function getTribeProductList(tribeId) {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/list-products?tribeID=${tribeId}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}

export async function getProductOverviewData(productId) {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products?product=${productId}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}

export async function getTotalProductCost() {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/budget/total-product`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}

export async function getTotalCostByQuarterData(productId) {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/cost-quarter?productID=${productId}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}

export async function getMetricKr(id, metricType) {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/metric?product=${id}&metric=${metricType}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}

export async function getCostAbsorbtionQuarter(quarter, productId) {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/cost-absorption?productID=${productId}&quarter=${quarter}`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (response.data.code === 403) {
      window.location.replace('/login')
    }
    return response.data
  } catch (e) {
    window.location.replace('/500');
  }
}