import React from 'react';

const Error500 = () => {
  return (
    <div className="page-notFound-container">
      <div className="page-404-text">500</div>
      <div className="page-notFound-text textCenter">Opps, Something Went Wrong!</div>
      <div className="page-notFound-text2">We apologize and are fixing the problem. Please try again at a later stage</div>
    </div>
  );
};

export default Error500;