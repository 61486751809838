import React, { useEffect, useState } from 'react';
import { Alert } from 'rsuite';
import { useHistory, useParams } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    Box,
    CircularProgress,
    Paper,
    Grid,
    TextField
} from '@material-ui/core';
import {
    filterOkrDataTable,
    getOkrAllProduct,
    getOkrQuarterAndDate
} from '../services/OkrOverview';
import OkrTable from '../components/okrTable';
import '../App.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
    },
    paper: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    user: {
        borderLeft: '1px solid grey'
    },
    progressContainer: {
        marginBottom: 20,
    },
    pageTitle: {
        fontSize: 24,
        fontWeight: 700,
    },
    paperonKey: {
        height: 22,
        width: 159,
        background: '#E06666',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 700,
        fontSize: 16,
        color: '#fff',
        borderRadius: 0
    },
    loadingContainer: {
        height: "100%",
    }
}));

const OkrOverview = (props) => {
    const classes = useStyles();
    const [okrLevel, setOkrLevel] = useState('');
    const [rot, setRot] = useState('');
    const [date, setDate] = useState(null);
    const [okrPeriod, setOkrPeriod] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState([]);
    const [okrData, setOkrData] = useState([]);
    const [okrAllProduct, setAllProduct] = useState([]);
    const [okrQuarterAndDate, setOkrQuarterAndDate] = useState([]);
    const [okrAllArchive, setOkrAllArchive] = useState([]);

    let history = useHistory();
    const { tokenParam } = useParams();
    if (tokenParam && tokenParam) {
        localStorage.setItem('token', tokenParam);
    }


    const handleChangeOkrLevel = (event) => {
        if (event) {
            setOkrLevel(event.productID);
        } else {
            setOkrLevel(null);
        }
        setDate(null);
        setOkrPeriod(null);
    };

    const handleChangeDate = (event) => {
        if (event) {
            setDate(event.date);
            dataTable(event.date, okrLevel);
        } else {
            setDate(null);
        }

    };

    const handleChangeOkrPeriod = (event) => {
        if (event) {
            setOkrPeriod(event.quarter);
            getDateForSelect(okrQuarterAndDate, event.quarter);
        } else {
            setOkrPeriod(null);
        }

        setDate(null);
    };

    const changeDateFormatDisplay = data => {
        let dateObj = new Date(data);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[dateObj.getMonth()];
        const day = String(dateObj.getDate()).padStart(2, '0');
        const year = dateObj.getFullYear();
        const output = `${day} ${month} ${year}`;
        return output
    }

    const getDateForSelect = (arr, period) => {
        let obj = arr.find(date => date.quarter === period);
        setSelectedDate(obj.listDate)
    }

    const convertDataToPercent = (data) => {
        if (data === null || data === undefined) {
            return 0
        } else {
            let percent = data * 100;
            if (percent > 100) {
                return 100
            } else {
                return parseFloat(percent.toFixed(2))
            }
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                await setLoading(true)
                await productList()
                await quarter()
                setLoading(false)
            } catch (err) {
                Alert.error("Failed to get Data")
                setLoading(false)
                history.push("/500")
            }
        };
        getData();
    }, [history])

    const productList = async () => {
        let list = await getOkrAllProduct()
        setAllProduct(list.data)
    }

    const quarter = async () => {
        let quarterSelect = await getOkrQuarterAndDate();
        setOkrQuarterAndDate(quarterSelect.data)
    }

    const dataTable = async (selectedDate, selectedProductId) => {
        let data = await filterOkrDataTable(selectedDate, selectedProductId);
        setOkrData(data.data);
        setRot(convertDataToPercent(data.ruleOfThumb));
        setOkrAllArchive(data.achieve)
    }

    if (loading) {
        return (
            <div className={classes.root}>
                <Grid container justifyContent="center" alignContent="center">
                    <div className={classes.loadingContainer}></div>
                    <CircularProgress color="secondary" />
                </Grid>
            </div>
        )
    } else {
        return (
            <div className={classes.root}>
                <Grid container spacing={5} direction="column">
                    <Grid item container>
                        <Grid item xs={12} className={classes.pageTitle}>
                            Objective And Key Result
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Paper className={classes.paper}>
                            <Grid item container>
                                <Grid item xs={6} md={3}>
                                    <p className="select-label">Product</p>
                                    <Autocomplete
                                        id="combo-box-product"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeOkrLevel(newValue);
                                        }}
                                        options={okrAllProduct}
                                        getOptionLabel={(option) => option.product_name}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <p className="select-label">OKR Period</p>
                                    <Autocomplete
                                        id="combo-box-period"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeOkrPeriod(newValue);
                                        }}
                                        value={okrPeriod}
                                        options={okrQuarterAndDate}
                                        getOptionSelected={(option, value) => option.quarter === value}
                                        getOptionLabel={(option) => option.quarter ? option.quarter : option}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <p className="select-label">Date</p>
                                    <Autocomplete
                                        id="combo-box-date"
                                        size="small"
                                        onChange={(event, newValue) => {
                                            handleChangeDate(newValue)
                                        }}
                                        disabled={okrPeriod ? false : true}
                                        value={date}
                                        options={selectedDate}
                                        getOptionSelected={(option, value) => option.date === value}
                                        getOptionLabel={(option) => option.date ? changeDateFormatDisplay(option.date) : changeDateFormatDisplay(option)}
                                        style={{ width: '90%' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <p className="select-label">Rule of Thumb Quarterly</p>
                                    <p className="text-content">{rot} %</p>
                                </Grid>
                            </Grid>
                            <Grid item container className={classes.progressContainer} >
                                <Grid item xs={6}>
                                    <p className="progress-label">Overall Progress</p>
                                    <div id="myProgress">
                                        {
                                            okrAllArchive.length === 0 || okrAllArchive[0].avg === null ? (
                                                <div id="myBar" style={{ width: `0%` }}></div>
                                            ) : (
                                                convertDataToPercent(okrAllArchive[0].avg) === 0 ? (
                                                    <div id="myBar" style={{ width: `${convertDataToPercent(okrAllArchive[0].avg)}%` }}></div>
                                                ) : (
                                                    <div id="myBar" style={{ width: `${convertDataToPercent(okrAllArchive[0].avg)}%` }}>{convertDataToPercent(okrAllArchive[0].avg)}%</div>
                                                )
                                            )

                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className="progress-label">Key Result Status</p>
                                    {
                                        okrAllArchive.length === 0 ? (
                                            <Box display="flex" flexDirection="row">
                                                <Paper elevation={0} className={classes.paperonKey} style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}></Paper>
                                                <Paper elevation={0} className={classes.paperonKey} style={{ background: '#FF9900' }} ></Paper>
                                                <Paper elevation={0} className={classes.paperonKey} style={{ background: '#6AA84F', borderTopRightRadius: 20, borderBottomRightRadius: 20 }} ></Paper>
                                            </Box>
                                        ) : (
                                            <Box display="flex" flexDirection="row">
                                                <Paper elevation={0} className={classes.paperonKey} style={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}>{okrAllArchive[0].red}</Paper>
                                                <Paper elevation={0} className={classes.paperonKey} style={{ background: '#FF9900' }} >{okrAllArchive[0].yellow}</Paper>
                                                <Paper elevation={0} className={classes.paperonKey} style={{ background: '#6AA84F', borderTopRightRadius: 20, borderBottomRightRadius: 20 }} >{okrAllArchive[0].green}</Paper>
                                            </Box>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container>
                        <OkrTable dataTable={okrData} rot={rot} />
                    </Grid>
                </Grid>
            </div >
        );
    }
}

export default OkrOverview