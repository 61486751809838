import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Home from './pages/Home'
import User from './pages/Users'
import Login from './pages/Login'
import Competitors from './pages/Competitors'
import Informations from './pages/Informations'
import KeyResults from './pages/KeyResults'
import Products from './pages/Products'
import Readiness from './pages/Readiness'
import Realizations from './pages/Realizations'
import Recapitulations from './pages/Recapitulations'
import SalesChannel from './pages/SalesChannels'
import DigitalThemes from './pages/digitalManagement/digitalThemes';
import Quarters from './pages/digitalManagement/quarters';
import OkrPage from './pages/OkrOverview';
import PortofolioPage from './pages/PortofolioOverview';
import ProductPage from './pages/ProductOverview';
import page400 from './pages/400Page';
import page500 from './pages/500Page';
import Tribes from './pages/digitalManagement/tribes';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Home} />
        <Route exact path="/OkrOverview/:tokenParam" component={OkrPage} />
        <Route exact path="/PortofolioOverview/:tokenParam" component={PortofolioPage} />
        <Route exact path="/ProductOverview/:tokenParam" component={ProductPage} />
        <Route exact path="/users" component={User} />
        <Route exact path="/competitors" component={Competitors} />
        <Route exact path="/product-informations" component={Informations} />
        <Route exact path="/key-results" component={KeyResults} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/ncx-readiness" component={Readiness} />
        <Route exact path="/budget-realizations" component={Realizations} />
        <Route exact path="/key-result-recaps" component={Recapitulations} />
        <Route exact path="/sales-channels" component={SalesChannel} />
        <Route exact path="/tribes" component={Tribes} />
        <Route exact path="/digital-themes" component={DigitalThemes} />
        <Route exact path="/quarters" component={Quarters} />
        <Route exact path="/500" component={page500} />
        <Route component={page400} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
