import React, { useEffect, useState } from 'react'
import { Grid, Row, Col, Table, InputGroup, Input, Icon, InputPicker, Button, Modal, Alert } from 'rsuite'

import Sidebar from '../components/Sidebar'
import { ListRole } from '../services/List'
import ReadAllUsers, { AddUser, DeleteUser, UpdatePassword, UpdateUser } from '../services/Users'

const { Column, HeaderCell, Cell, Pagination } = Table

function Users() {
  const [listUser, setListUser] = useState({ data: [], pageInfo: {} })
  const [limit, setLimit] = useState(10)
  const [searchKey, setSearchKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const pageInfo = listUser.pageInfo
  const limitOptions = [
    {
      value: 10,
      label: 10
    },
    {
      value: 20,
      label: 20
    },
    {
      value: 50,
      label: 50
    }
  ]

  const searchOptions = [
    {
      value: 'username',
      label: 'Username'
    },
    {
      value: 'd.name',
      label: 'Name'
    },
    {
      value: 'role',
      label: 'role'
    }
  ]
  const [title, setTitle] = useState('')
  const [userId, setUserId] = useState('')
  const [username, setUsername] = useState('')
  let [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [role, setRole] = useState('')
  const [update, setUpdate] = useState(false)
  const [show, setShow] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [disableUsername, setDisableUsername] = useState(true)
  const [listRole, setListRole] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  async function getAllUser() {
    const response = await ReadAllUsers() || { data: [], pageInfo: {} }
    setListUser(response)
    getAllRoles()
  }
  async function getAllRoles() {
    const results = []
    const lists = await ListRole() || []
    for (const list of lists) {
      results.push({
        value: list.role,
        label: list.role
      })
    }
    setListRole(results)
  }

  useEffect(() => {
    getAllUser()

  }, [])

  async function changePage(e) {
    pageInfo.page = e
    const data = [pageInfo.page, limit, searchKey, searchValue]
    const response = await ReadAllUsers(data)
    setListUser(response)
  }
  async function changeLimit(e) {
    setLimit(e)
    const data = [pageInfo.page, e, searchKey, searchValue]
    const response = await ReadAllUsers(data)
    setListUser(response)
  }
  async function searchData(e) {
    setSearchValue(e)
    const data = [pageInfo.page, limit, searchKey, e]
    const response = await ReadAllUsers(data)
    setListUser(response)
  }
  async function addData(e) {
    const data =
    {
      "fullname": name,
      "username": username,
      "password": password
    }
    const response = await AddUser(data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    setShow(!show)
    getAllUser()
  }
  async function updateData(e) {
    const id = userId
    const data =
    {
      "fullname": name,
      "username": username,
      "role": role
    }
    const response = await UpdateUser(id, data)
    response.userDetail.code === 200 ? Alert.success(response.userDetail.message) : Alert.error(response.userDetail.message)
    const dataList = [pageInfo.page, limit, searchKey, searchValue]
    getAllUser(dataList)
    setShow(!show)
  }
  async function updatePassword(e) {
    password = password || ''
    if (password.length < 5) {
      Alert.error('Password minimum 5 characters')
      return
    }
    const id = userId
    const data =
    {
      "username": username,
      "password": password
    }
    const response = await UpdatePassword(id, data)
    response.code === 200 ? Alert.success(response.message) : Alert.error(response.message)
    const dataList = [pageInfo.page, limit, searchKey, searchValue]
    getAllUser(dataList)
    setShow(!show)
    setShowPassword(!showPassword)
  }
  async function deleteData(e) {
    const id = userId
    const response = await DeleteUser(id)
    response ? Alert.success('Delete stage User') : Alert.error('Failed to delete user')
    setShowDelete(!showDelete)
    setShow(!show)
    const data = [pageInfo.page, limit, searchKey, searchValue]
    getAllUser(data)
  }

  function addNewData() {
    setTitle('Add ')
    setUserId('')
    setUsername('')
    setPassword('')
    setRole('')
    setName('')
    setDisableUsername(false)
    setUpdate(false)
    setShow(true)
  }
  function choiceUpdate() {
    if (update) {
      return <div>
        <Button onClick={updateData} appearance="primary">Update</Button>
        <Button color="red" onClick={() => setShowDelete(true)} appearance="primary">Delete</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    } else {
      return <div>
        <Button onClick={addData} appearance="primary">Add</Button>
        <Button onClick={() => setShow(!show)} appearance="subtle">Cancel</Button>
      </div>
    }
  }
  async function handleSearchKey(e) {
    setSearchKey(e)
    if (e === null) {
      setSearchValue('')
      const data = [pageInfo.page, limit, '', '']
      const response = await ReadAllUsers(data)
      setListUser(response)
    }
  }

  return (
    <>
      <Grid fluid>
        <Col md={4}>
          <Sidebar keyActive={'2'} openKey={''} />
        </Col>
        <Col md={1}></Col>
        <Col md={17}>
          <Row style={{ marginTop: 18 }}>
            <h2>Users</h2>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'normal' }}>
            <InputPicker placeholder="Search By" data={searchOptions} style={styles} onChange={handleSearchKey} />
            <InputGroup style={styles}>
              <Input value={searchValue} onChange={searchData} />
            </InputGroup>
            <Button style={{ float: 'right', marginLeft: '45%', fontSize: '0.8rem', height: '50%' }} appearance='primary' onClick={addNewData}>
              Add New  <Icon icon='plus-square' style={{ marginLeft: 5 }} />
            </Button>
          </Row>
          <Row style={{ marginTop: 25 }}>

            <Table
              virtualized
              height={500}
              width={500}
              data={listUser.data}
              onRowClick={data => {
                setUserId(data.userID)
                setUsername(data.username)
                setPassword(data.password)
                setRole(data.role)
                setName(data.name)
                setTitle('Update ')
                setUpdate(true)
                setShow(true)
                setDisableUsername(true)
              }}
              loading={listUser.data ? false : true}
            >

              <Column width={150} align="center">
                <HeaderCell>Username</HeaderCell>
                <Cell dataKey="username" />
              </Column>
              <Column width={250} align="center">
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
              </Column>
              <Column width={100} align="center">
                <HeaderCell>Role</HeaderCell>
                <Cell dataKey="role" />
              </Column>

            </Table>

            <Pagination
              activePage={pageInfo.page}
              pages={pageInfo.totalPage}
              total={pageInfo.totalData}
              boundaryLinks={true}
              ellipsis={true}
              lengthMenu={limitOptions}
              displayLength={limit}
              onSelect={(e) => changePage(e)}
              onChangeLength={(e) => changeLimit(e)}
            />

          </Row>
        </Col>
      </Grid>

      <Modal show={show} size='xs' onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>{title.concat('User')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Username</InputGroup.Addon>
            <Input disabled={disableUsername} value={username} onChange={(e) => setUsername(e)} />
          </InputGroup>
          {update ? '' :
            <InputGroup style={modals}>
              <InputGroup.Addon style={input}> Password</InputGroup.Addon>
              <Input type="password" value={password} onChange={(e) => setPassword(e)} />
            </InputGroup>}
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Full Name</InputGroup.Addon>
            <Input value={name} onChange={(e) => setName(e)} />
          </InputGroup>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>Role</InputGroup.Addon>
            <InputPicker placeholder=" " value={role} data={listRole} onChange={(e) => setRole(e)} style={{ border: "none", width: '24rem' }} />
          </InputGroup>
          {update ?
            <Button style={{ marginBottom: 10, float: 'left' }} onClick={() => setShowPassword(true)} apperance="secondary">Change Password</Button> : ''}
        </Modal.Body>
        <Modal.Footer>
          {choiceUpdate()}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} size='xs' onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <h5>Are you sure want to delete this item ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button color="red" onClick={deleteData}>Yes</Button>
          <Button onClick={() => setShowDelete(!showDelete)} appearance="subtle">No</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPassword} size='xs' onHide={() => setShowPassword(false)}>
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={modals}>
            <InputGroup.Addon style={input}>New Password</InputGroup.Addon>
            <Input type="password" value={password} onChange={(e) => setPassword(e)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={updatePassword} appearance="primary">Update Password</Button>
          <Button onClick={() => setShowPassword(!showPassword)} appearance="subtle">Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const styles = {
  width: 200,
  marginTop: 10,
  marginBottom: 10,
  marginRight: 20
}

const modals = {
  marginTop: 15,
  marginBottom: 15,
}

const input = {
  width: '7rem'
}

export default Users