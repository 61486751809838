import axios from "axios";

export async function filterOkrDataTable(dateRecap, productID) {
    try {
        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/okr?productID=${productID}&date=${dateRecap}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (response.data.code === 403) {
            window.location.replace('/login')
        }
        return response.data
    } catch (e) {
        window.location.replace('/500');
    }
}

export async function getOkrAllProduct() {
    try {
        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BACKEND + `/api/products/data-all`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (response.data.code === 403) {
            window.location.replace('/login')
        }
        return response.data
    } catch (e) {
        window.location.replace('/500');
    }
}

export async function getOkrQuarterAndDate() {
    try {
        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BACKEND + `/api/overview/v1/products/list-quarter`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (response.data.code === 403) {
            window.location.replace('/login')
        }
        return response.data
    } catch (e) {
        window.location.replace('/500');
    }
}
